<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="employer">    
    <h3>
        <ng-container *ngIf="isEmployerOrStaff()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isAdmin()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isAuditor()">
            Auditor on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
    </h3>
    <span class="pageHeader">Create a New Report</span>
    <p style="text-align: left;">Select from the following options and then click on an agreement to create a report. Fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required.</p>
    <table *ngIf="systemParameters.length > 0">
        <ng-container *ngIf="employer.massImport != null && employer.massImport > 0">
            <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Create reports from mass import." style="float: right;" (click)="goToMassImport()">Payroll Import</button>
        </ng-container>
        <table>
            <tr>
                <td class="right nowrap"><span class="required">Reporting Period From*</span><strong>:</strong></td>
                <td class="smallPadding">
                    <ng-container *ngIf="isAffirmative(systemParameters[0].parameterValue1!); else dc">
                        <mat-form-field class="cal2Width" color="primary" appearance="fill" >
                            <mat-label>Reporting Period From</mat-label>
                            <input matInput [matDatepicker]="picker2" [readonly]="true" [(ngModel)]="reportDate.dateString" [min]="minDate.dateString"
                                [max]="maxDate.dateString" (dateChange)="datechange()">
                            <!-- <mat-hint>MM DD,YYYY</mat-hint> -->
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 color="primary"></mat-datepicker>
                        </mat-form-field>
                    </ng-container>
                    <ng-template #dc>
                        <mat-form-field class="cal1Width">
                            <input matInput [matDatepicker]="dp" [formControl]="date" [readonly]="true"
                                [min]="minDate.dateString" [max]="maxDate.dateString">
                                <!-- <mat-hint>MM DD,YYYY</mat-hint> -->
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </ng-template>
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><span class="required">Report Type*</span><strong>:</strong></td>
                <td class="smallPadding">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        color="primary" [(ngModel)]="reportType">
                        <mat-radio-button class="radio-button" *ngFor="let item of erfTypeList;let i = index"
                            [value]="item.guid" [checked]="item.guid===reportType">
                            {{item.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </td>
            </tr>
            <ng-container *ngIf="checkIfAllVeba()">
                <tr>
                    <td class="right nowrap"><span class="required">Data Source*</span><strong>:</strong></td>
                    <td class="smallPadding">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            color="primary" [(ngModel)]="reportSource">
                            <mat-radio-button class="radio-button" *ngFor="let item of dataSourceList;let i = index"
                                [value]="item.guid" [checked]="item.guid===reportSource">
                                {{item.name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="right nowrap"><strong>Employer Report Form ID:</strong></td>
                <td class="smallPadding">
                    <input property="contributionReportID" size="20" maxlength="16" title="Employer Report Form ID" [(ngModel)]="contributionReportID" />
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><strong>Sort Order:</strong></td>
                <td class="smallPadding">First:&nbsp;&nbsp;
                    <select (change)="selectSort1Change(sort1);checkSorter();" name="sort1" [(ngModel)]="sort1">
                        <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                            {{ item.name }}
                        </option>
                    </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Second:&nbsp;&nbsp;
                    <select (change)="selectSort2Change(sort2);checkSorter();" name="sort2" [(ngModel)]="sort2">
                        <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                            {{ item.name }}
                        </option>
                    </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third:&nbsp;&nbsp;
                    <select (change)="selectSort3Change(sort3);checkSorter();" name="sort3" [(ngModel)]="sort3">
                        <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                            {{ item.name }}
                        </option>
                    </select>
                </td>
            </tr>
        </table>
        <br>
        <ng-container *ngIf="agreementList.length == 0">
            <h6 class="agreementCount">No Agreement found</h6>
        </ng-container>
        <ng-container *ngIf="agreementList.length != 0">
            <h6 class="agreementCount">{{agreementList.length}} Agreements found, displaying all Agreements.</h6>
        </ng-container>
        <mat-table [dataSource]="agreementList" class="agreementList">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="No">
                <mat-header-cell *matHeaderCellDef class="header"> No. </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </mat-cell>
            </ng-container>

            <!-- No Craft Hire Column -->
            <ng-container matColumnDef="NCH">
                <mat-header-cell *matHeaderCellDef class="header"> NCH </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <input type="checkbox" (click)="open(nchModal);setNCHData(element)">
                </mat-cell>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef class="header"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" class="statusAgree">
                    <ng-container *ngIf="agreementStatusCount(element.agreement.guid) == 0; else sc">
                        <label style="color: red;">
                            Not Reported
                        </label>
                    </ng-container>
                    <ng-template #sc>
                        <label style="color:green;">
                            Finalized ({{agreementStatusCount(element.agreement.guid)}})
                        </label>
                    </ng-template>
              
                </mat-cell>
            </ng-container>

            <!-- Agreement Column -->
            <ng-container matColumnDef="Agreement">
                <mat-header-cell *matHeaderCellDef class="header agreementHeaderPadding"> Agreement </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                    <ng-container *ngIf="agreementStatusCount(element.agreement.guid) == 0; else sc">
                        <button mat-button class="agreementAction statusAgree additionalPadding" (click)="createNewAgreement(element)" style="color: red;">
                            {{element.agreement.agreementLongName}} - {{element.agreement.cbaId}}{{setAgreementLineEnd(element)}}
                        </button>
                    </ng-container>
                    <ng-template #sc>
                        <button mat-button class="agreementAction statusAgree additionalPadding" (click)="createNewAgreement(element)" style="color: green;">
                            {{element.agreement.agreementLongName}} - {{element.agreement.cbaId}}{{setAgreementLineEnd(element)}}
                        </button>
                    </ng-template>
                    
                </mat-cell>
            </ng-container>

            <!--Statement Column -->
            <ng-container matColumnDef="Statement">
                <mat-header-cell *matHeaderCellDef class="header additionalPadding"> {{statementDate.dateString | date:'MMMM'}} Statement </mat-header-cell>
                <mat-cell *matCellDef="let element">
                   <!-- Veba Statement -->
                   <ng-container *ngIf="element.agreement.masterAgreement.isVEBA">
                        <button mat-icon-button color="primary" (click)="getVebaStatement(element)" class="additionalPadding">
                            <mat-icon>list_alt</mat-icon>
                        </button>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="checkIfAllVeba() ? displayedColumns: displayedColumnsIsVeba"></mat-header-row>
            <mat-row *matRowDef="let row; columns: checkIfAllVeba() ? displayedColumns: displayedColumnsIsVeba;"></mat-row>
        </mat-table>
    </table>

</body>




<!-- No Craft Hire Modal -->
<ng-template #nchModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">No Craft Hired</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Do you want to create a No-Craft-Hired report for this agreement?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click');createNewAgreement(nchData)">Yes</button>
    </div>
</ng-template>

<!-- Select Previous Modal -->
<ng-template #selectPreviousModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Previous Report</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');cancelSelectPrevious();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Select a previous report on which to base your new report and then click the Create Report button.</p>
        <table class="fullwidth">
			<tr>
				<td class="right nowrap" width="20%"><span class="required">Previous Report*</span><strong>:</strong></td>
				<td>
                    <!-- <ng-container *ngFor="let rpt of selectPreviousData;let i = index"> -->
                        <select [(ngModel)]="selectPrevious">
                            <option *ngFor="let rpt of selectPreviousData;let i = index" [ngValue]="rpt">
                                {{ rpt.workPeriodBegin | date:'MM/dd/YYYY'}} {{ rpt.ediRefNo }} ({{rpt.employeeCount}} Employees Listed)
                            </option>
                        </select>
                    <!-- </ng-container> -->
					  <!-- <nested:select property="erfGuid">
					    <nested:iterate property="employerReportVO">
					      <option value="<nested:write property="guid" />"><nested:write property="periodFrom" format="MM/dd/yyyy"/>&nbsp;&nbsp;<nested:write property="ediRefNoFormatted" />&nbsp;&nbsp;(<nested:write property="employeeListSize" /> Employee<nested:notEqual property="employeeListSize" value="1">s</nested:notEqual> listed)</option>
					    </nested:iterate>
					  </nested:select> -->
				</td>
			</tr>
		</table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click');cancelSelectPrevious();">Cancel</button>
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click');createNewAgreement(selectPrevious)">Create Report</button>
    </div>
</ng-template>


<!-- Import Select File Modal -->
<ng-template #importSelectFile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Upload Payroll Import File</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label for="file">Select File:</label>
        <input type="file" (change)="selectFile($event)"/>
        <br>
        Header row #:
        <input property="headerRow" size="20" maxlength="16" title="Excel Sheet Header Row" [(ngModel)]="headerRow" />
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark"
            (click)="checkImportFile();">Continue</button>
    </div>
</ng-template>

<!-- Import Report Setup Modal -->
<ng-template #importSetup let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Report Import Setup</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Assign a column number to each applicable field matching the import file specified:</p>
        <div class="erfFormatList">
            <table style="border: solid;">
                <tr style="border: solid;">
                    <td style="border: solid;">Position</td>
                    <td>Column</td>
                </tr>
                <ng-container *ngFor="let column of agreementColumns;let i = index">
                    <tr style="border: solid;">
                        <td style="border: solid;">
                            <ng-container *ngIf="defaultMapping == null;else noDefault">
                                <input property="headerRow" size="4" maxlength="3" title="Assigned Mapping" [(ngModel)]="agreementColumnsSortOrder[i]" />
                            </ng-container>
                            <ng-template #noDefault>
                                <input property="headerRow" size="4" maxlength="3" title="Assigned Mapping" [(ngModel)]="agreementColumnsSortOrder[i]" />
                            </ng-template>
                        </td>
                        <td>{{column}}</td>
                    </tr>
                </ng-container>
            </table>
        </div>
        <div class="fileFormatList">
            <p>Here are the the column headings from the import file
                with their corresponding column numbers.&nbsp; Fill in the appropriate
                column numbers in the boxes on the left.</p>

            <label style="font-weight: bold;">Mapping Format: </label>
            <select (change)="getMapping();" name="selectMapping"[(ngModel)]="defaultMapping">
                <ng-container *ngIf="importNamesList.length > 0;else noMapping" >
                    <option *ngFor="let item of importNamesList" [ngValue]="item.guid">
                        {{ item.name }}
                    </option>
                </ng-container>
                <ng-template #noMapping>
                    <option>Create New Mapping On Preview!</option>
                </ng-template>
            </select>
            
            <mat-icon color="primary" title="To Create New Mapping: Do mapping here then, Create new mapping on preview report page!" style="position: absolute;">info</mat-icon>
            
            <table style="border: solid;">
                <tr style="border: solid;">
                    <td style="border: solid;">Position</td>
                    <td>Column</td>
                </tr>
                <ng-container *ngFor="let fHeaders of fileImportFormat; let i = index">
                    <tr style="border: solid;">
                        <td style="border: solid;">{{i + 1}}.</td>
                        <td>{{fHeaders}}</td>
                    </tr>
                </ng-container>
            </table>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click');open(importPreview)">Preview Report</button>
    </div>
</ng-template>

<!-- Import Preview Modal -->
<ng-template #importPreview let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Report Import Setup</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Assign a column number to each applicable field matching the import file specified:</p>
        <div class="erfFormatList">
            <table style="border: solid;">
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of agreementColumns;let i = index">
                        <td style="border: solid;padding: 0px 5px 0px 5px;">{{column}}</td>
                    </ng-container>
                </tr>
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of agreementColumnsSortOrder;let i = index">
                        <ng-container *ngIf="sortPreview(column);else noMatch">
                            <td style="border: solid;padding: 0px 5px 0px 5px;">{{fileColumnsPreview[column-1]}}</td>
                        </ng-container>
                    </ng-container>
                    <ng-template #noMatch>
                        <td style="border: solid;padding: 0px 5px 0px 5px;"></td>
                    </ng-template>
                </tr>
            </table>
        </div>
        <div style="float: left;">
            <br>
            <p>If this mapping has not been saved before, Save a new mapping before trying to create report!</p>
            <label style="font-weight: bold;">Mapping Format: </label>
            <input property="headerRow" size="20" maxlength="20" title="Mapping Format" [(ngModel)]="newMappingName" />
            <button type="button" class="btn btn-outline-dark" (click)="saveNewmapping()">Save</button>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button> -->
        <button type="button" class="btn btn-outline-dark" (click)="previewReport()">Make Changes</button>
        <button type="button" class="btn btn-outline-dark" (click)="createImportReport()">Create Report</button>
    </div>
</ng-template>

<!-- By Pay Period Modal -->
<ng-template #byPayPeriodModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Pay Period</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <select (change)="selectPayPeriod($event);" name="selectMapping">
            <option>--Select--</option>
            <option *ngFor="let period of byPayPeriodDates">
                {{ period | date : 'longDate'}}
            </option>
        </select>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="byPayPeriod()">Create Report</button>
    </div>
</ng-template>
<!-- PDF Modal -->
<ng-template #pdfModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Employer's Report of Contributions</h1>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header" style="white-space: nowrap;">
        <button type="button" class="btn btn-outline-dark" (click)="getVebaStatementExcel()">Download as Excel File</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/pdf" width="100%" height="800px" ></object>
        </p>
    </div>
</ng-template>
<!-- Excel Modal -->
<ng-template #excelModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Employer's Report of Contributions</h1>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-header" style="white-space: nowrap;">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click');getVebaStatementExcel()">Export to Excel File</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/vnd.ms-excel" width="100%" height="800px" ></object>
        </p>
    </div>
</ng-template>