<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="employer">
    <h3>
        <ng-container *ngIf="isEmployerOrStaff()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff() && !isLiaison() && !isAuditor() && !isPayRoll()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isAuditor()">
            Auditor on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isPayRoll()">
            Payroll Company
        </ng-container>
    </h3>
    <span class="pageHeader">Report History</span>


    <div id="leftcol" class="leftcol">
        <h4><span class="noprint title">Select Unfinished Employer Reporting Form</span></h4><br>
        <p><span class="noprint titleDesc">To resume processing, select an unfinished report from the list below:</span></p>
        <div class="treeview">
            <br>
            <p class="listHeaders">
                <mat-icon>folder_open</mat-icon><strong style="position: absolute;">Unfinished Reports</strong>
            </p>
            <!-- TREE HERE -->
            <ng-container *ngIf="unfinishedReports.length > 0">
                <ng-container *ngFor="let item of unfinishedReports; let i = index">
                    <ng-container *ngIf="item.referenceNo == null && item.agreement != null">
                        <li class="listLeft">
                            <ng-container *ngIf="!isLiaison()">
                                <button mat-icon-button color="warn" (click)="deleteSetItem(item);open(deleteModal)"  [disabled]="item.massImportInfo != null" class="small-icon-button">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-container>
                            <button title="Last updated {{dateToString(item.lastUpdateDate)}} at {{item.lastUpdateDate | date:'shortTime'}}" mat-button color="primary" class="agreementLink" (click)="goToLink(item)" [disabled]="isLiaison()">
                                {{ dateToString(item.workPeriodBegin)}} {{item.agreement.cbaId}} -
                                {{item.agreement.agreementLongName}} - {{setAgreementLineEnd(item)}}
                            </button>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div id="rightcol" class="rightcol">
        <h4><span class="noprint title">View Finalized Employer Reporting Form</span></h4><br>
        <p><span class="noprint titleDesc">To view a finalized employer reporting form, select from the list below:</span></p>
        <div class="treeview">
            <br>
            <p class="listHeaders">
                <mat-icon>folder_open</mat-icon><strong style="position: absolute;">Finalized Reports</strong>
            </p>
            <!-- TREE HERE -->
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <!-- <button mat-icon-button disabled></button> -->
                    <button title="Last updated {{dateToString(node.name.lastUpdateDate)}} at {{node.name.lastUpdateDate | date:'shortTime'}}" mat-button color="primary" class="agreementLink" (click)="getPdf(node.name)">
                        <mat-icon>create</mat-icon>
                        {{ dateToString(node.name.workPeriodBegin) }} {{node.name.agreement.cbaId}} -
                        {{node.name.agreement.agreementLongName}} - {{setAgreementLineEnd(node.name)}}
                    </button>
                    <ng-container *ngIf="node.name.massImportInfo != null">
                        <button mat-icon-button color="primary" (click)="showMassCoupon(node.name)">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                    </ng-container>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    {{node.name}}
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>

</body>


<!-- Delete Modal -->
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Unfinished Report</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete this report? 
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="warn" mat-raised-button class="btn btn-outline-dark" (click)="deleteAction();modal.close('Save click')">Yes</button>
    </div>
</ng-template>
<!-- Delete unfinalizeReport Modal -->
<ng-template #unfinalizeReportModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Un-Finalize Report</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        By un-finalizing this report, this report could now be deleted or re-finalized. Any existing extract that contained this report or payment will not be modified.
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="warn" mat-raised-button class="btn btn-outline-dark" (click)="unfinalizeReport();modal.close('Save click')">Yes</button>
    </div>
</ng-template>
<!-- PDF Modal -->
<ng-template #pdfModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Computation of Payments</h1>
    </div>
    <div class="modal-header" style="white-space: nowrap;">
        <!-- TODO:// Check if ADMIN and CAN UNFINALIZE REPORT -->
        <div style="float: left;">
            <ng-container *ngIf="isAffirmative(systemParameters[0].parameterValue1!)|| 
			(systemParameters[0].parameterValue1 != 'Y' && systemParameters[0].parameterValue1 != 'N') ||
			(systemParameters[0].parameterValue1 == 'N' && (employerReport.referenceNo != null && employerReport.referenceNo != ''))">
            <ng-container *ngIf="!showingMassCoupon">
                <ng-container *ngIf="!pdfButton; else pdfBtn">
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processPayment();">Payment Coupon</button>
                </ng-container>
                <ng-template #pdfBtn>
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processReport();">Calculated Report</button>
                </ng-template>
            </ng-container>
            </ng-container>
        </div>
        <div style="float: right;">
            <ng-container *ngIf="isAffirmative(systemParameters[1].parameterValue1!)  && (employerReport.referenceNo != null && employerReport.referenceNo != '') && role === 'WEBERFADMIN'">
                <button type="button" color="warn" mat-raised-button class="btn btn-outline-dark" title="Unfinalize Report" (click)="modal.close('Save click');open(unfinalizeReportModal);" [disabled]="employerReport.massImportInfo != null">Unfinalize Report</button>
            </ng-container>
            <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');" style="margin-left:5px">Close</button>
        </div>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/pdf" width="100%" height="800px" ></object>
        </p>
    </div>
</ng-template>