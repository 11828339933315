import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild,ViewEncapsulation, Renderer2 } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ErfEmployee } from 'src/app/model/erfEmployee/erf-employee';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';
import { EmployeeWorkHistoryFundDetail } from "src/app/model/employeeWorkHistoryFundDetail/employee-work-history-fund-detail";
import { FundDetail } from 'src/app/model/employeeWorkHistoryFundDetail/fund-detail';
import { TrustFund } from 'src/app/model/employeeWorkHistoryFundDetail/trust-fund';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { EmployerReportService } from 'src/app/services/employerReport/employer-report.service';
import { take } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver'; 
import { FormatFieldConstants } from '../../../model/constants/format-field-constants';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { FundDetails } from 'src/app/model/fundDetails/fund-details';
import { FundItems } from 'src/app/model/fundDetails/fund-items';
import { ImportService } from 'src/app/services/import/import.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-remittance-report',
  templateUrl: './remittance-report.component.html',
  styleUrls: ['./remittance-report.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class RemittanceReportComponent implements OnInit {

  employerId:any = '';
  systemParameters: SystemParameters[] = [];
  agreement: any;
  role: any = ''; 
  employerInfo:any;
  erfPayment:any;
  // employer!: Employer;
  employerReport:any;
  employer: any;
  employerAddress: any;
  employerPhone: any;
  params: any = ["EMPLOYER_NOTES","JCDISPLAYOPTION","ER_PROJECT_MAINT",
  "INCL_DEMOGRAPHICS_ON_ERF","OWNER_OPERATOR","CUSTOM_PRELIST","HRS_REG_ALLOW_ZERO",
  "HRS_MAX","HRS_MIN","WAGES_MAX","WAGES_MIN","SSN_NAME_VALIDATION","PAYMENT_COUPON_B4_FINAL",
  "CHECK_MESSAGE","EFT_MESSAGE","UNFINALIZE_ADMIN_ENABLE","EFT_ACTIVATED","EFT_ALLOW_SHORT_PAYMENT","EFT_PAYMENT_HTML",
  "EFT_CUTOFF_DAY","EFT_CUTOFF_MONTHS","ALLOW_WEEKENDS", "SPECIAL_COUPON_FORMAT","MUST_REPORT_OOP"];
  deliveryMethodList: any[] = [];
  statesList: any[] = [];
  tiles: Tile[] = [
    {text: 'One', cols: 2, rows: 1, color: 'lightblue'},
    {text: 'Two', cols: 1, rows: 3, color: 'lightgreen'},
    {text: 'Three', cols: 2, rows: 1, color: 'lightpink'},
    {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  ];

  tmpDemoQ: any;

  finalize = false;
  dates:any = [];
  pdfData:any;
  fileURL:any;
  pdfButton = false;
  @ViewChild('alertModal') alertModal : any;
  @ViewChild('pdfModal') pdfModal : any;
  @ViewChild('finalizeModal') finalizeModal : any;
  @ViewChild('importSelectFile') importSelectFile : any;
  @ViewChild('importSetup') importSetup : any;
  @ViewChild('importPreview') importPreview : any;
  @ViewChild('employerClassMappingModal') employerClassMappingModal : any;
  ///////////////////////////////////
  ownerOperatorActive = false;
  private oopDefaults: any[] = [];
  defaultJob:any = 0;
  defaultProject:any = '0';
  defaultCraft:any = '0';
  defaultHoursClassGuid:any = '0';
  blankErfForm:any;
  employmentStatusReason:any[] = [];
  erfSortOrder: any[] = [];
  sort1: number = 0;
  sort2: number = 0;
  sort3: number = 0;
  reportId: number = 0;
  agreementId:number = 0;
  ediRefNo = false;
  isProjectBased = false;
  //////////////////////////////////
  modalOptions: NgbModalOptions;
  @ViewChild('validationModal') myModal : any;
  closeResult: string = '';
  formatFields:any;
  // Table columns
  initColumnsData: any[] = [];
  // Displayed columns array of strings
  displayedColumnsData: any[] = [];
  fullNameDefaultData: any[] = [
    {
      name: 'lastName',
      display: 'Last Name',
    },
    {
      name: 'firstName',
      display: 'First Name',
    },
    {
      name: 'mi',
      display: 'Middle Initial',
    },
  ];
  dataSourceOwnerOperator = new MatTableDataSource<ErfEmployee>([]);
  dataSource = new MatTableDataSource<ErfEmployee>([]);
  @ViewChild(MatTable) table!: MatTable<any>;
  tFundPerEmp = 0;
  trustFundGuids:any =[];
  deleteEmpData: any;
  deleteEmpDataIndex: any;
  dataToSave: any = [];

  //For Import TODO: condense into a model
  selectedFiles?: FileList;
  currentFile?: File;
  headerRow: any = 1;
  importReady = false;
  importType : any[] = [];
  agreementColumns:any;
  agreementColumnsSortOrder:any;
  fileImportFormat: any;
  fileColumnsPreview:any[] = [];
  // importAgreementDetails:any;
  newMappingName:any;
  allExistingMappings:any;
  importNamesList:any;
  defaultMapping:any = null;
  importedEmployees:any[] = [];
  importSelected = false;

  hoursNotMapped:any[] = [];
  jobsNotMapped:any[] = [];
  workingLocalNotMapped:any[] = [];
  hoursToBeMapped:any[] = [];
  jobsToBeMapped:any[] = [];
  workingLocalToBeMapped:any[] = [];
  employmentReasonNotMapped:any[] = [];
  employmentReasonToBeMapped:any[] = [];
  //End Import

  //Validation handlers
  minHrs:number = 0;
  maxHrs:number = 0;
  minWages:number = 0;
  maxWages:number = 0;
  allowNeg:boolean = false;
  HRS_REG_ALLOW_ZERO:boolean = false;
  inputBackgroundColor = 'rgb(255,255,255,0.8)';
  validationEnabled = false;
  focusField = '';
  alertMsg = '';
  private isRoleEmployer: any = "EmployerUserVO";
  private isRoleEmployerStaff: any = "EmployerStaffUserVO";
  private isRoleAdmin: any = "AdminUserVO";
  private isRoleAuditor: any = "AuditorUserVO";
  private isPayRollCompany: any = "PayrollCompanyUserVO";

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,
    public authService: AuthService,
    private systemParameterService: SystemParametersService,
    private userManagementService: UserManagementService,
    private employerReportService: EmployerReportService,
    private importService: ImportService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }

  loadData() {
    this.loader.show()
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    //Get Employer Details
    this.getUserInfo();
  }

  setValidationValues(){
    this.allowNeg = (Boolean(this.employerReport.erfType.allowNeg));
    // this.minHrs = (this.allowNeg) ? Number(this.systemParameters[8].parameter_VALUE) : 0;
    this.minHrs = Number(this.systemParameters[8].parameterValue1);
    this.maxHrs = Number(this.systemParameters[7].parameterValue1);
    this.minWages = Number(this.systemParameters[10].parameterValue1);
    this.maxWages = Number(this.systemParameters[9].parameterValue1);
    this.HRS_REG_ALLOW_ZERO = this.isAffirmative(this.systemParameters[6].parameterValue1!);
  }

  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
    this.agreementId = Number(atob(this.route.snapshot.paramMap.get('agreementId')!));
    this.reportId = Number(atob(this.route.snapshot.paramMap.get('reportId')!));
    if(this.reportId == 0){
      this.sort1 = Number(atob(this.route.snapshot.paramMap.get('sort1')!));
      this.sort2 = Number(atob(this.route.snapshot.paramMap.get('sort2')!));
      this.sort3 = Number(atob(this.route.snapshot.paramMap.get('sort3')!));
    }
  }

  getUserInfo(): void {
    if(this.reportId != 0){
      this.dataSource.data = [];
      this.userManagementService
        .getUserRemittanceReport(this.agreementId,this.employerId)
        .subscribe(
          (response: any) => {
            this.isProjectBased = response.result.isProjectBased;
            this.employmentStatusReason = response.result.employmentStatusReason;
            // console.log('this.employmentStatusReason');
            // console.log(this.employmentStatusReason);
            this.blankErfForm = response.result.blankErfForm;
            if(this.blankErfForm.jobClassList.length == 1){
              this.defaultJob = this.blankErfForm.jobClassList?.[0].guid;
            }
            if(this.blankErfForm.erfOOpList != null && this.blankErfForm.erfOOpList.length > 0){
              this.ownerOperatorActive = true;
            }
            if(this.blankErfForm.allowedHoursClasses.length == 1){
              this.defaultHoursClassGuid = this.blankErfForm.defaultHoursClassGuid;
            }

            this.employerReport = response.result.employerReport;
            this.fixPayment();
            this.agreement = response.result.employerReport.erCba;
            this.employer = response.result.employerReport.employer;
            this.setEmployerAddress(response.result.employerReport.employer);
            this.erfSortOrder = response.result.erfSortOrder;
            this.sort1 = response.result.employerReport.sort1.guid;
            this.sort2 = response.result.employerReport.sort2.guid;
            this.sort3 = response.result.employerReport.sort3.guid;
            this.deliveryMethodList = response.result.deliveryMethodList;
            this.statesList = response.result.statesList;
            this.employerInfo = response.result.employerInfo;
            this.erfPayment = response.result.erfPayment;
            this.formatFields = response.result.erfformatField;
            //Set Up inital reported employees and owner operator
            if(response.result.employerReport.reportedEmployees != null){
              this.dataSource.data = response.result.employerReport.reportedEmployees;
            }
            this.dataSource.data.push(new ErfEmployee(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, 
              new EmployeeWorkHistoryFundDetail(null,null,null,null,
                new FundDetail(new TrustFund(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null), 
                new ErfEmployee(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,new FundDetails(),null)),
                null,null,null,null,null),new FundDetails(),null));

                this.setDefaultValues();

            // console.log(this.dataSource.data);
            //Get System Parameters
            this.systemParameterService
              .getSystemParameterDetails(this.params)
              .subscribe(
                (responseP: any) => {
                  this.systemParameters = responseP.result;
                  this.setUpErfFormatFields(response.result.erfformatField);
                  //Set values for field validation
                  this.setValidationValues();
                  if(this.ownerOperatorActive){
                    this.userManagementService
                        .makeOOPDefaults(this.agreementId,this.employerId)
                        .subscribe(
                          (response: any) => {
                            this.oopDefaults = response.result;
                          },
                          (error: HttpErrorResponse) => {
                            alert(error.error.reason);
                          }
                        );
                  }

                  //This is for if they click import
                  if(this.importSelected){
                    this.importSelected = false;
                    this.openPdf(this.importSelectFile);
                  }else{
                    this.authService.refreshToken().subscribe((result)=> {}, () => {});
                  }
                },
                (error: HttpErrorResponse) => {
                  alert(error.error.reason);
                }
              );
              this.table.renderRows();
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
        );
    }else{
      // TODO: new agreement need to create and Save an employerReport then retrive data
    }
  }

  setUpErfFormatFields(res:any): void {
    this.trustFundGuids=[];
    this.initColumnsData = [];
    this.displayedColumnsData = [];
    // console.log(res);
    var count = 0;
    var tFundCount = 0;
    while (count < res.length) {
      var data = res[count];
      var ignore = res[count].erfField.guid;
      var fieldName = res[count].erfField.fieldName;
      if (ignore != 11 && ignore != 14890862 && data.trustFund == null) {
        //Check if DemoGraphics is enabled and add before remove
        if(this.isAffirmative(this.systemParameters[3].parameterValue1!) && fieldName === "obsolete"){
          this.initColumnsData.push({
            name: "Demographics",
            display: "Demographics",
            displayOnly:'',
            required: false,
            tFundGuid: 0
          });
        } else if(fieldName.includes('empStatus|')){
          this.initColumnsData.push({
            name: "emplStatusDate",
            display: "Employment Date",
            displayOnly:'',
            required: false,
            tFundGuid: 0
          });
          this.initColumnsData.push({
            name: "emplStatusReasonGuid",
            display: "Employment Reason",
            displayOnly:'',
            required: false,
            tFundGuid: 0
          });
        }else{
          this.initColumnsData.push({
            name: fieldName,
            display: data.columnHeading,
            displayOnly:'',
            required: data.entryRequired,
            tFundGuid: 0
          });
        }
      } else if (ignore == 11 && data.trustFund == null) {
        var i = 0;
        while (i < this.fullNameDefaultData.length) {
          // console.log(this.fullNameDefaultData[i].name);
          // console.log(this.fullNameDefaultData[i].display);
          if(i != this.fullNameDefaultData.length -1){
            this.initColumnsData.push({
              name: this.fullNameDefaultData[i].name,
              display: this.fullNameDefaultData[i].display,
              displayOnly:'',
              required: true,
              tFundGuid: 0
            });
          }else{
            this.initColumnsData.push({
              name: this.fullNameDefaultData[i].name,
              display: this.fullNameDefaultData[i].display,
              displayOnly:'',
              required:false,
              tFundGuid: 0
            });
          }
          i++;
        }
      } else if (data.trustFund != null) {
        var re = '{0}';
        var str = data.trustFund.fundColumnHeading;
        var cName: any = data.trustFund?.fundShortName;
        var newstr = '';
        if(data.columnHeading == null || data.columnHeading == undefined || data.columnHeading.length == 0 || (data.columnHeading != null && data.columnHeading != undefined && data.columnHeading.includes(re))){
          newstr = data.columnHeading.replace(re, cName);
        } else {
          newstr = data.columnHeading;
        }
        this.initColumnsData.push({ 
          name: 'fund'+data.fieldOrder, //'fundDetails[fund'+data.fieldOrder+'].original'
          display: newstr,
          tFundGuid: data.trustFund?.guid,
          displayOnly: data.displayOnly, 
          required: data.entryRequired, 
          index: (this.trustFundGuids.length - 1), column:data.erfField.fieldName});

        this.tFundPerEmp = this.trustFundGuids.length;
      }
      count++;
    }

    this.displayedColumnsData = this.initColumnsData.map((col) => col.name);
    // console.log("initColumnsData");
    // console.log(this.initColumnsData);
    // console.log("displayedColumnsData");
    // console.log(this.displayedColumnsData);
    // console.log("trustFundGuids");
    // console.log(this.trustFundGuids);
    // this.IsWait = false;
  }
  setEmployerAddress(data:any):void{
    for(let i = 0; i < data.locations.length;i++){
      if (data.locations[i].obsolete){
        continue;
      }
      if(data.locations[i].locationType == 'PRIMARY'){
        for(let j = 0; j < data.locations[i].addressHistory.length; j++){
          var effDate = data.locations[i].addressHistory[j].effDate;
          var expDate = data.locations[i].addressHistory[j].expDate;
          if(data.locations[i].addressHistory[j].obsolete){
            continue;
          }
          if(expDate == null){
            if(new Date(effDate!) <= new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              this.employerPhone = data.locations[i];
            }
          } else {
            if(new Date(effDate!) < new Date() && new Date(expDate) > new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              this.employerPhone = data.locations[i];
            }
          }
        }
      }
    }
  }

  setAgreementLineEnd(data:any){
    var tmp:string = data.rateAsOfType.parameterCode;
    var str:string = tmp.match("FROZEN") ? ": Frozen (" + this.datePipe.transform(data.rateAsOfDate,'M/d/YYYY') + ")" : "";
    str = str + this.setProjectTag(data);
    return str;
  }

  setProjectTag(data:any){
    if(this.isProjectBased){
      if(data.projects != null && data.projects != undefined && data.projects.length != 0){
        return '['+data.projects[0].project.projectName +']';
      }
    }
    return ""
  }

  jcdisplayoption(job:any){
      if(job == null){
        return "-";
      }
      return job.parameterDesc;
  }
  hcdisplayoption(hc:any){
    if(hc == null){
      return "-";
    }
    return hc.parameterDesc;
  }
  wldisplayoption(wl:any){
    if(wl == null){
      return "-";
    }
    return wl.organizationID;
  }
  esrdisplayoption(esr:any){
    if(esr == null){
      return null;
    }
    const val = this.employmentStatusReason.find((obj:any) => {
      return obj.guid === esr;
    });
    return (val != undefined) ? val.parameterDesc : esr.parameterDesc;
  }
  isMatch(val:any,str:string){
    return val.match(str);
  }
  isAffirmative(s: string) {
    if (
      s != null && s != undefined &&
      (s.toUpperCase().match('Y') ||
        s.toUpperCase().match('YES') ||
        s.match('1'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkDisableInputs(row: any,index:any): boolean {
    if (
      row.ssn != null &&
      row.ssn != 'undefined' &&
      row.ssn.replaceAll('-', '').length == 9 &&
      row.firstName != null &&
      row.firstName != 'undefined' &&
      row.firstName != '' &&
      row.lastName != null &&
      row.lastName != 'undefined' &&
      row.lastName != '' &&
      index < (this.dataSource.data.length-1)
    ) {
      return false;
    }
    return true;
  }
  // Disabled if preListStatus == V
  // Enabled if preListStatus == P
  // Enabled if preListStatus == A
  checkRemoveDisabled(row:any){
    if(row.preListStatus == 'V'){//Veba Prelisted Employee
      return true;
    }
    return false;
  }
  displayOnly(column:any){
    if(column.displayOnly == "Y"){
      return true;
    }
    return false;
  }
  addRow(index:any){
    // add default job to JOBCLASS column TODO: FIX ME WHEN IN USE
    for(var job of this.blankErfForm.jobClassList){
      if(job.guid == this.defaultJob){
        this.dataSource.data[index].jobClass = job;
      }
    }
    // add default project to Project column TODO: FIX ME WHEN IN USE
    for(var project of this.employerReport.erCba.projects){
      if(this.defaultProject === '0'){
        this.dataSource.data[index].project = null;
        this.dataSource.data[index].projectID = null;
        break;
      } else if(this.defaultProject == project.guid){
        this.dataSource.data[index].project = project;
        this.dataSource.data[index].projectID = project.employerProjectID;
      }
    }


    this.dataSource.data.push(new ErfEmployee(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,{guid:0},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, 
      new EmployeeWorkHistoryFundDetail(null,null,null,null,
        new FundDetail(new TrustFund(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null), 
        new ErfEmployee(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, new FundDetails(),null)),
        null,null,null,null,null), new FundDetails(),null));

        this.setDefaultValues();
    // console.log(this.dataSource.data.length);
    this.table.renderRows();
  }
  checkAddRow(name: any,index:any,row:any){
    if(this.alertMsg != ''){return;}
    if(!this.validationEnabled){
      // console.log(row.fundDetails.fund5);
      // console.log("name",name);
      // console.log("row",row);
      // console.log("index",index);
      var valid:boolean = false;
      var lastRow = this.dataSource.data[this.dataSource.data.length-1];
      var lastRowValid = false;
      if((lastRow.ssn != null && lastRow.ssn != '' && lastRow.ssn.replaceAll('-', '').length == 9) && (lastRow.lastName != null && lastRow.lastName != '') && (lastRow.firstName != null && lastRow.firstName != '')){
        lastRowValid = this.fullValidation(name,index,row);
        valid = lastRowValid;
        if(!lastRowValid){return;}
      } 
      else if((this.dataSource.data.length-1) == index){
        lastRowValid = this.fullValidation(name,index,row);
        valid = lastRowValid;
        if(!lastRowValid){return;}
      }
      // else if ((name == 'lastName' && (lastRow.lastName == null || lastRow.lastName == '')) || (name == 'firstName' && (lastRow.firstName == null || lastRow.firstName == ''))){
      //   lastRowValid = this.fullValidation(name,index,row);
      //   valid = lastRowValid;
      //   if(!lastRowValid){return;}
      // }
      if((this.dataSource.data.length-1) != index){
        valid = this.fullValidation(name,index,row);
      }

      // if((this.dataSource.data.length-1) != index){
      //   valid = this.fullValidation(name,index,row);
      // }else if(((this.dataSource.data.length-1) == index) && (name != 'ssn' && name != 'lastName' && name != 'firstName')){
      //   valid = this.fullValidation(name,index,row);
      // }
    
      if(valid && lastRowValid && this.enableRowCheck()){
        this.addRow(index);
        if(!this.ssnValidation()){return;}
        //this is to check last row 
        // for(let name of this.displayedColumnsData){
        //   valid = this.fullValidation(name,index,this.dataSource.data[index]);
        //   if(!valid){
        //     break;
        //   }
        // }
      }
    }
    this.validationEnabled = false;
  }

  setDeleteEmpData(emp: any, index: any) {
    // console.log(emp);
    this.deleteEmpDataIndex = index;
    this.deleteEmpData = emp;
  }
  setDemoEmpData(emp: any, index: any) {
    this.tmpDemoQ = emp;
  }

  open(content: any) {
    // console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openPdf(content: any) {
    // console.log('open');
    this.modalService.open(content, {backdrop: 'static',backdropClass: 'customBackdrop',size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  isSticky (column: string): boolean {
    return column === 'ssn' ? true : false;
  }
  enableRowCheck():Boolean{
    var count = 0;
    //Check each employee, make sure each has SSN/FIRST-LAST NAME before adding row
    while (count < this.dataSource.data.length) {
      var ssn = this.dataSource.data[count].ssn;
      var firstName = this.dataSource.data[count].firstName;
      var lastName = this.dataSource.data[count].lastName;
      if (
        ssn != null &&
        ssn != 'undefined' &&
        ssn.replaceAll('-', '').length == 9 &&
        firstName != null &&
        firstName != 'undefined' &&
        firstName != '' &&
        lastName != null &&
        lastName != 'undefined' &&
        lastName != '' 
      ) {
      } else {
        return false;
      }
      count++;
    }
    return true;
  }
  deleteErfEmployee(): void {
    // console.log(this.deleteEmpData);
    if(this.deleteEmpData.guid == null){
      // remove from list becuase its not a saved value
      this.dataSource.data.splice(this.deleteEmpDataIndex, 1);
      this.dataSource._updateChangeSubscription();
    }else{
      // Delete Employee from report than list
      this.employerReportService
        .deleteEmployeeFromReport(this.deleteEmpData.guid)
        .subscribe(
          (response: any) => {
            alert(response.message);
            this.dataSource.data.splice(this.deleteEmpDataIndex, 1);
            this.dataSource._updateChangeSubscription();
          },
          (error: HttpErrorResponse) => {
            alert(error.error.message);
          }
        );
    }
  }
  orderEquals(val1: any, val2: any) {
    return val1 != 6 && val2 != 6 && val1 == val2;
  }
  checkSorter() {
    if (this.sort1 != 6 || this.sort2 != 6 || this.sort3 != 6) {
      if (
        this.orderEquals(this.sort1, this.sort2) ||
        this.orderEquals(this.sort1, this.sort3) ||
        this.orderEquals(this.sort2, this.sort3)
      ) {
        alert('Sort Order must be unique');
        return false;
      }
    }
    return true;
  }
  selectSort1Change(data: any) {
    this.employerReport.sort1 = this.getSortData(data);
  }
  selectSort2Change(data: any) {
    this.employerReport.sort2 = this.getSortData(data);
  }
  selectSort3Change(data: any) {
    this.employerReport.sort2 = this.getSortData(data);
  }
  getSortData(guid:any){
    const val = this.erfSortOrder.find((obj) => {
      return obj.guid === guid;
    });
    return val;
  }
  changeFirstBool(){
    this.employerReport.firstReportInArea = !this.employerReport.firstReportInArea;
  }
  changeLastBool(){
    this.employerReport.lastReportInArea = !this.employerReport.lastReportInArea;
  }
  changeSupReport(){
    if(this.employerReport.isSupplementalReport == 'N'){
      this.employerReport.isSupplementalReport = 'Y'
    }else {
      this.employerReport.isSupplementalReport = 'N'
    }
  }
  changeProject(data:any, row:any){
    const x = document.getElementById(data) as HTMLInputElement;
    var val:any;
    if((this.employerReport.erCba.signatoryType != null) && (this.isMatch(this.employerReport.erCba.signatoryType.parameterValue1,'SINGLE') || this.isMatch(this.employerReport.erCba.signatoryType.parameterValue1,'APPROVAL'))){
      val = this.employerReport.erCba.projects.find((obj:any) => {
        return obj.project.projectID === x.value || obj.project.projectName === x.value;
      });
      row.project = val;
      row.projectID = val.project.projectID;
    } else {
      val = this.employerReport.employerProjects.find((obj:any) => {
        return obj.project.projectID === x.value || obj.project.projectName === x.value;
      });
      row.project = val;
      if(val.project.projectID == null || val.project.projectID == undefined){
        row.projectID = val.project.projectName;
      }else{
        row.projectID = val.project.projectID;
      }
    }
  }
  
  changeEmployementStatusReason(data:any,row:any){
    const x = document.getElementById(data) as HTMLInputElement;
    // console.log(x.value);
    const val = this.employmentStatusReason.find((obj:any) => {
      return obj.parameterDesc === x.value;
    });
    row.emplStatusReasonGuid = val.guid;
  }
  changeSelectField(data:any,row:any,type:String){
    const x = document.getElementById(data) as HTMLSelectElement;
    // console.log(x.value);
    if(x != null){
      if(type=='JobClassVO'){
        const val = this.blankErfForm.jobClassList.find((obj:any) => {
          return obj.parameterDesc.trim() === x.value.trim();
        });
        row.jobClass = val;
      }
      if(type=='HoursClassVO'){
        const val = this.blankErfForm.allowedHoursClasses.find((obj:any) => {
          return obj.parameterDesc.trim() === x.value.trim();
        });
        row.hoursClass  = val;
      }
      if(type=='OrganizationDisplayFlagVO'){
        const val = this.blankErfForm.unionLocals.find((obj:any) => {
          return obj.organizationID.trim() === x.value.trim();
        });
        row.workingLocal  = val;
      }
    }
  }
  getSelectedValue(listItem:string,row:any,type:String){
    if(type=='JobClassVO' || type=='HoursClassVO'){
      if(row?.parameterDesc == undefined){
        return false;
      } else {
        return listItem.trim() === row.parameterDesc.trim();
      }
    }
    if(type=='OrganizationDisplayFlagVO'){
      if(row?.organizationID == undefined){
        return false;
      } else {
        return listItem.trim() === row.organizationID.trim();
      }
    }
    return false;
  }

  validation(type:any) {
    if(!this.ssnValidation()){return;}
    this.dataToSave = [];
    var count = 0;
    var lastRecord = this.dataSource.data[this.dataSource.data.length-1];
    if((lastRecord.ssn == null || lastRecord.lastName == null || lastRecord.firstName == null) && 
        (lastRecord.ssn != null || lastRecord.lastName != null || lastRecord.firstName != null)){
      if(!(lastRecord.ssn == null || lastRecord.ssn == '') ||
         !(lastRecord.lastName == null || lastRecord.lastName == '') ||
         !(lastRecord.firstName == null || lastRecord.firstName == '')){
           alert('Last row is incomplete. \nPlease complete or clear all data from that row before continuing.');
           return;
         }
    }
    while (count < this.dataSource.data.length -1) {
      // Validate all data per employee
      var valid:boolean = false;
      for(let name of this.displayedColumnsData){
        valid = this.fullValidation(name,count,this.dataSource.data[count]);
        if(!valid){
          break;
        }
      }
      if (valid) {
        for(let i = 1; i < 40; i++){
          var o = this.dataSource.data[count].fundDetails['fund'+i].original;
          // var v = this.dataSource.data[count].fundDetails['fund'+i].vebaDue;
          var v = this.dataSource.data[count].fundDetails['fund'+i].vebaCalc;
          if(v != null && o == v){
            this.dataSource.data[count].fundDetails['fund'+i].original = null;//they are only the same for angular needs to be reset to null for spring
          }
        }
        this.dataToSave.push(this.dataSource.data[count]);
      } else {
        this.open(this.myModal);
        return;
      }
      count++;
    }
    //Not good sort option selected
    if(!this.checkSorter()){
      return;
    }

    this.saveData(type);
  }
  saveData(type:any){
    var tmpEmpTF:any = [];
    var count = 0;
    //Prepare Final tfund array
    for(var x of this.dataToSave){
      tmpEmpTF.push([]);
    }
    //Replace / Add New Fund Data per Employee
    for(let i = 0; i < tmpEmpTF.length; i++){
      this.dataToSave[i].employeeWorkHistoryFundDetail = [];
      for(let j = 0; j < tmpEmpTF[i].length; j++){
        this.dataToSave[i].employeeWorkHistoryFundDetail.push(tmpEmpTF[i][j]);
      }
    }
    // console.log(tmpEmpTF);
    // console.log(this.dataToSave);
    this.employerReport.reportedEmployees = [];
    for(let i = 0; i < this.dataToSave.length; i++){
      this.employerReport.reportedEmployees.push(this.dataToSave[i]);
    }
    // console.log(this.employerReport);
    if(type == "SAVE" || type == "PEND"){
      this.employerReportService
        .saveReport(this.employerReport)
        .subscribe(
          (response: any) => {
            // console.log(response);
            if(type == "PEND"){
              if(this.employerReport.massImportInfo != null){
                this.redirectTo('/reportOptions/massImport');
              }else{
                this.redirectTo('/reportOptions');
              }
            }else{
              this.loadData();
            }
            // this.table.renderRows();
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
        );
    } else if (type == 'CALCULATE'){
      this.employerReportService
      .calculateReport(this.employerReport)
      .subscribe((response:any) => {
        this.pdfData = response.result;
        this.employerReportService.pdfReport(response.result)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {this.loadData();}, () => {});
          // this.loadData();
        });
        // let file = new Blob([response], { type: 'application/pdf' });           
        // this.fileURL = URL.createObjectURL(file);
        // this.openPdf(this.pdfModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      });
    } else {//if("FINALIZE"){
      this.processFinalize(this.employerReport);
    }
  }
  pdfURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }

  clearForm(){
    for(let i = 0; i < this.dataSource.data.length-1; i++){
      this.dataSource.data[i].hoursReportedDT = 0;
      this.dataSource.data[i].hoursReportedOT = 0;
      this.dataSource.data[i].hoursReportedST = 0;
      this.dataSource.data[i].wages = 0;
      this.dataSource.data[i].project = null;
      this.dataSource.data[i].projectID = null;
      this.dataSource.data[i].emplStatusReasonGuid = null;
      this.dataSource.data[i].emplStatusDate = null;
      //Set funddetails to null + special veba column details
      this.clearFundDetails(this.dataSource.data[i].fundDetails);
    }
    this.table.renderRows();
  }
  //This will set all funds that are not veba fund to null
  //  Veba columns will get reset to the fundHoursApplied
  clearFundDetails(data:FundDetails){
    data.fund0.original = data.fund0.vebaDue;
    data.fund1.original = data.fund1.vebaDue;
    data.fund2.original = data.fund2.vebaDue;
    data.fund3.original = data.fund3.vebaDue;
    data.fund4.original = data.fund4.vebaDue;
    data.fund5.original = data.fund5.vebaDue;
    data.fund6.original = data.fund6.vebaDue;
    data.fund7.original = data.fund7.vebaDue;
    data.fund8.original = data.fund8.vebaDue;
    data.fund9.original = data.fund9.vebaDue;
    data.fund10.original = data.fund10.vebaDue;
    data.fund11.original = data.fund11.vebaDue;
    data.fund12.original = data.fund12.vebaDue;
    data.fund13.original = data.fund13.vebaDue;
    data.fund14.original = data.fund14.vebaDue;
    data.fund15.original = data.fund15.vebaDue;
    data.fund16.original = data.fund16.vebaDue;
    data.fund17.original = data.fund17.vebaDue;
    data.fund18.original = data.fund18.vebaDue;
    data.fund19.original = data.fund19.vebaDue;
    data.fund20.original = data.fund20.vebaDue;
    data.fund21.original = data.fund21.vebaDue;
    data.fund22.original = data.fund22.vebaDue;
    data.fund23.original = data.fund23.vebaDue;
    data.fund24.original = data.fund24.vebaDue;
    data.fund25.original = data.fund25.vebaDue;
    data.fund26.original = data.fund26.vebaDue;
    data.fund27.original = data.fund27.vebaDue;
    data.fund28.original = data.fund28.vebaDue;
    data.fund29.original = data.fund29.vebaDue;
    data.fund30.original = data.fund30.vebaDue;
    data.fund31.original = data.fund31.vebaDue;
    data.fund32.original = data.fund32.vebaDue;
    data.fund33.original = data.fund33.vebaDue;
    data.fund34.original = data.fund34.vebaDue;
    data.fund35.original = data.fund35.vebaDue;
    data.fund36.original = data.fund36.vebaDue;
    data.fund37.original = data.fund37.vebaDue;
    data.fund38.original = data.fund38.vebaDue;
    data.fund39.original = data.fund39.vebaDue;
  }
  deleteReport() {
    if(this.employerReport.guid != null){
      this.employerReportService
        .deleteEmployerReport(this.employerReport.guid)
        .subscribe(
          (response: any) => {
            this.redirectTo('/listReports');
          },
          (error: HttpErrorResponse) => {
            alert(error.message);
          }
        );
    } else {
      alert("Could Not Delete This Report Has Never Been");
    }
  }
  redirectTo(str:string){
    if(this.employerId === '' || this.employerId == null || this.employerId === undefined){
      this.router.navigate([str]);
    } else {
      this.router.navigate([
        str,
        { employerId: btoa(this.role == this.isPayRollCompany ? this.authService.getPGUID() : this.employerReport.employer.guid) },
      ]);
    }
  }

  fullValidation(name: any,index:any,row:any){
    // console.log(row);
    this.focusField = name+index
    if(name == 'ssn'){
      if(row.ssn == null || row.ssn.replaceAll('-','').length != 9 || row.ssn == 'undefined' || row.ssn == '' || !Number(row.ssn.replaceAll('-',''))){
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        if((this.dataSource.data.length-1) != index){
          this.createAlert("Please enter a valid 9 digit SSN");
        }
        // alert("Please Enter a valid 9 digit SSN");
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }
    else if(name == 'firstName'){
      if(row.firstName == null || row.firstName == 'undefined' || row.firstName == ''){
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        if((this.dataSource.data.length-1) != index){
          this.createAlert("Please enter a valid First Name");
        }
        // alert("Please Enter a Valid First Name");
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }
    else if(name == 'lastName'){
      if(row.lastName == null || row.lastName == 'undefined' || row.lastName == ''){
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        if((this.dataSource.data.length-1) != index){
          this.createAlert("Please enter a valid Last Name");
        }
        // alert("Please Enter a Valid Last Name");
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    } else if(name == 'wages'){
      // validation based on system params
      var errorMsg = '';
      var absWages = Math.abs(row.wages);

      if(row.ownerOperator && this.isAffirmative(this.systemParameters[23].parameterValue1!)){
        const oopdWage = this.oopDefaults.find((obj:any) => {
          return obj.employeeGUID === row.guid;
        });
        if(row.wages < oopdWage.defaultWages){
          errorMsg = "Wages minimum is $" + oopdWage.defaultWages + " for " + this.systemParameters[4].parameterValue1! + ".";
        }
      }

      if(this.minWages != 0 && row.wages == 0){
        errorMsg = "Please enter wages.";
      }

      if (row.wages != null && isNaN(row.wages)) {
        errorMsg = "Please enter a numeric value";
      } else if (this.allowNeg && this.minWages == 0 && (row.wages > this.maxWages || row.wages < (-this.maxWages))) {
        errorMsg = "Wages value should be between " + (-this.maxWages) + " and " + this.maxWages;
      } else if (this.allowNeg && this.minWages != 0 && (absWages > this.maxWages || absWages < this.minWages)) {
        errorMsg = "Wages value should be between " + (-this.maxWages) + " and " + (-this.minWages) + " or " + this.minWages + " and " + this.maxWages;
      } else if (!this.allowNeg && (row.wages > this.maxWages || row.wages < this.minWages)) {
        errorMsg = "Wages value should be between " + this.minWages + " and " + this.maxWages;
      } else if ((row.hoursReportedST*row.wages) < 0) {
        errorMsg = "Signs of hours and wages must be the same";
      } else if (row.hoursReportedST > 0 && (!(row.wages > 0) && this.allowNeg)) {
        errorMsg = "You must enter wages if the value entered in the Hours field is greater than zero.";
      }else if(this.decimalCount(row.wages) >= 3){
        errorMsg = "Wages can only be up to 2 decimal places";
      }
      if(errorMsg != ''){
        row.wages = 0;
        document.getElementById(name+index)!.style.backgroundColor = 'lightgoldenrodyellow';
        this.createAlert(errorMsg);
        // alert(errorMsg);
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }else if(name == 'hoursReportedST'){
      // validation based on system params
      var errorMsg = '';
      var absHours = Math.abs(row.wages);

      if(row.ownerOperator && this.isAffirmative(this.systemParameters[23].parameterValue1!)){
        const oopdWage = this.oopDefaults.find((obj:any) => {
          return obj.employeeGUID === row.guid;
        });
        if(row.hoursReportedST < oopdWage.defaultHours){
          errorMsg = "Hours minimum is " + oopdWage.defaultHours + " for " + this.systemParameters[4].parameterValue1! + ".";
        }
      }

      if (this.HRS_REG_ALLOW_ZERO && row.hoursReportedST == 0) {
        row.hoursReportedST = 0; // Unnecessary except to make this clause valid
      } else {
        if (row.hoursReportedST != null && isNaN(row.hoursReportedST)) {
          row.hoursReportedST = 0;
          errorMsg = "Please enter a numeric Hours value";
        } else if (this.allowNeg && this.minHrs == 0 && (row.hoursReportedST > this.maxHrs || row.hoursReportedST < (-this.maxHrs))) {
          errorMsg = "Hours value should be between " + (-this.maxHrs) + " and " + this.maxHrs;
          row.hoursReportedST = 0;
        } else if (this.allowNeg && this.minHrs != 0 && (absHours > this.maxHrs || absHours < this.minHrs)) {
          errorMsg = "Hours value should be between " + (-this.maxHrs) + " and " + (-this.minHrs) + " or " + this.minHrs + " and " + this.maxHrs;
          row.hoursReportedST = this.minHrs;
        } else if (!this.allowNeg && (row.hoursReportedST > this.maxHrs || row.hoursReportedST < this.minHrs)) {
          errorMsg = "Hours value should be between " + this.minHrs + " and " + this.maxHrs;
          row.hoursReportedST = this.minHrs;
        }else if(this.decimalCount(row.hoursReportedST) >= 3){
          errorMsg = "hours can only be up to 2 decimal places";
        }
        if(errorMsg != ''){
          document.getElementById(name+index)!.style.backgroundColor = 'lightgoldenrodyellow';
          this.createAlert(errorMsg);
          // alert(errorMsg);
          return false;
        }else{
          document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
        }
      }
    }else if(name == 'hoursReportedOT'){
      // validation based on system params
      var errorMsg = '';
      var absHours = Math.abs(row.wages);
      if (row.hoursReportedOT != null && isNaN(row.hoursReportedOT)) {
        row.hoursReportedOT = 0;
        errorMsg = "Please enter a numeric OT Hours value";
      } else if (row.hoursReportedOT == 0) {
        errorMsg = '';	// This is always fine for OT. We also don't enforce a minimum.
      } else if (this.allowNeg && (row.hoursReportedOT > this.maxHrs || row.hoursReportedOT < (-this.maxHrs))) {
        errorMsg = "OT Hours value should be between " + (-this.maxHrs) + " and " + this.maxHrs;
        row.hoursReportedOT = 0;
      } else if (!this.allowNeg && (row.hoursReportedOT < 0 || row.hoursReportedOT > this.maxHrs)) {
        errorMsg = "OT Hours value should be between 0 and " + this.maxHrs;
        row.hoursReportedOT = 0;
      }else if(this.decimalCount(row.hoursReportedOT) >= 3){
        errorMsg = "Hours can only be up to 2 decimal places";
      }
      if(errorMsg != ''){
        document.getElementById(name+index)!.style.backgroundColor = 'lightgoldenrodyellow';
        this.createAlert(errorMsg);
        // alert(errorMsg);
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }else if(name == 'hoursReportedDT'){
      // validation based on system params
      var errorMsg = '';
      var absHours = Math.abs(row.wages);
      if (row.hoursReportedDT != null && isNaN(row.hoursReportedDT)) {
        row.hoursReportedDT = 0;
        errorMsg = "Please enter a numeric DT Hours value";
      } else if (row.hoursReportedDT == 0) {
        errorMsg = '';	// This is always fine for OT. We also don't enforce a minimum.
      } else if (this.allowNeg && (row.hoursReportedDT > this.maxHrs || row.hoursReportedDT < (-this.maxHrs))) {
        errorMsg = "DT Hours value should be between " + (-this.maxHrs) + " and " + this.maxHrs;
        row.hoursReportedDT = 0;
      } else if (!this.allowNeg && (row.hoursReportedDT < 0 || row.hoursReportedDT > this.maxHrs)) {
        errorMsg = "DT Hours value should be between 0 and " + this.maxHrs;
        row.hoursReportedDT = 0;
      }else if(this.decimalCount(row.hoursReportedDT) >= 3){
        errorMsg = "Hours can only be up to 2 decimal places";
      }
      if(errorMsg != ''){
        document.getElementById(name+index)!.style.backgroundColor = 'lightgoldenrodyellow';
        this.createAlert(errorMsg);
        // alert(errorMsg);
        return false;
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }else if(name.includes('fund')){
      // Trust Fund validation
      if(row.fundDetails[name].original < 0){
        // this.empTFundData[i][count].contributionsReported = null;
        alert("Negative number is not allowed in fund amounts");
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        return false;
      } else if(this.decimalCount(row.fundDetails[name].original) >= 3){
        alert("Fund Details can only be up to 2 decimal places");
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        return false;
      } else if (isNaN(row.fundDetails[name].original) && row.fundDetails[name].original != null && row.fundDetails[name].original != undefined && row.fundDetails[name].original != '') {
        alert("Please enter a numeric Fund details value");
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
        return false;
      }else {
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }else if(name == "emplStatusReasonGuid" || name == "emplStatusDate"){
      const requiredCheck = this.formatFields.filter((obj: { erfField: { fieldName: string | string[]; }; }) => {
        return obj.erfField.fieldName.includes("empStatus|");
      });
      //They must both have data or must both be null
      //This is also required for new employees, aka. prelistStatus = A & ErfFormat is set as required field
      //Not required for prelistStatus = P
      //Date Cannot be past current date
      if(row.preListStatus == 'A' && requiredCheck[0].entryRequired){
        if((row.emplStatusReasonGuid == null || row.emplStatusReasonGuid == undefined || row.emplStatusReasonGuid == '') && 
          (row.emplStatusDate == null || row.emplStatusDate == undefined || row.emplStatusDate == '')){
          document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = 'lightcoral';
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = 'lightcoral';
          alert("New Employees Must have Employment Status & Date assigned.");
          return false;
        } else if((row.emplStatusReasonGuid == null || row.emplStatusReasonGuid == undefined || row.emplStatusReasonGuid == '') && 
                  (row.emplStatusDate != null || row.emplStatusDate != undefined || row.emplStatusDate != '')){
          document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = 'lightcoral';
          alert("New Employees Must have Employment Status & Date assigned.");
          return false;
        }else if((row.emplStatusReasonGuid != null || row.emplStatusReasonGuid != undefined || row.emplStatusReasonGuid != '') && 
                (row.emplStatusDate == null || row.emplStatusDate == undefined || row.emplStatusDate == '')){
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = 'lightcoral';
          alert("New Employees Must have Employment Status & Date assigned.");
          return false;
        } else if(row.emplStatusDate > new Date()){
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = 'lightcoral';
          alert("Employment Date can not be after today.");
          return false;
        }else {
          document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = this.inputBackgroundColor;
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = this.inputBackgroundColor;
        }
      } else {
        if((row.emplStatusReasonGuid == null || row.emplStatusReasonGuid == undefined || row.emplStatusReasonGuid == '') && 
          (row.emplStatusDate == null || row.emplStatusDate == undefined || row.emplStatusDate == '')){
            document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = this.inputBackgroundColor;
            document.getElementById("emplStatusDate"+index)!.style.backgroundColor = this.inputBackgroundColor;
        } else if((row.emplStatusReasonGuid == null || row.emplStatusReasonGuid == undefined || row.emplStatusReasonGuid == '') && 
                (row.emplStatusDate != null || row.emplStatusDate != undefined || row.emplStatusDate != '')){
          document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = 'lightcoral';
          alert("Employment Status & Date must both have data or must both be cleared.");
          return false;
        }else if((row.emplStatusReasonGuid != null || row.emplStatusReasonGuid != undefined || row.emplStatusReasonGuid != '') && 
              (row.emplStatusDate == null || row.emplStatusDate == undefined || row.emplStatusDate == '')){
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = 'lightcoral';
          alert("Employment Status & Date must both have data or must both be cleared.");
          return false;
        } else if(row.emplStatusDate > new Date()){
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = 'lightcoral';
          alert("Employment Date can not be after today.");
          return false;
        } else {
          document.getElementById("emplStatusReasonGuid"+index)!.style.backgroundColor = this.inputBackgroundColor;
          document.getElementById("emplStatusDate"+index)!.style.backgroundColor = this.inputBackgroundColor;
        }
      }

    }else{
      const val = this.initColumnsData.find((obj:any) => {
        return obj.name === name;
      });
      if(val.required){
        if(row[name] == null || row[name] == 'undefined' || row[name] == '' || row[name].guid == null || row[name].guid == null || row[name].guid == 0){
          document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
          this.createAlert("Please enter a valid "+val.display);
          // alert("Please Enter a Valid "+val.display);
          return false;
        }else{
          document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
        }
      }
    }
    
    //Total Hours Check
    // Dont check if its newest employee being added
    if(name == 'hoursReportedST' || name == 'hoursReportedOT' || name == 'hoursReportedDT'){
      if (this.HRS_REG_ALLOW_ZERO && index+1 != this.dataSource.data.length){
        var totalHours = Math.abs(row.hoursReportedST) + Math.abs(row.hoursReportedOT) + Math.abs(row.hoursReportedDT);
        if (totalHours == 0) {
          document.getElementById("hoursReportedST"+index)!.style.backgroundColor = 'lightgoldenrodyellow';
          if(this.checkIfRowExisits("hoursReportedOT") != undefined && this.checkIfRowExisits("hoursReportedOT").name === "hoursReportedOT"){
            document.getElementById("hoursReportedOT"+index)!.style.backgroundColor = 'lightgoldenrodyellow';
          }
          if(this.checkIfRowExisits("hoursReportedDT") != undefined && this.checkIfRowExisits("hoursReportedDT").name === "hoursReportedDT"){
            document.getElementById("hoursReportedDT"+index)!.style.backgroundColor = 'lightgoldenrodyellow';
          }
          this.createAlert("At least some hours must be reported on each line.");
          // alert("At least some hours must be reported on each line.");
          return false;
        }else{
          document.getElementById("hoursReportedST"+index)!.style.backgroundColor = this.inputBackgroundColor;
          if(this.checkIfRowExisits("hoursReportedOT") != undefined && this.checkIfRowExisits("hoursReportedOT").name === "hoursReportedOT"){
            document.getElementById("hoursReportedOT"+index)!.style.backgroundColor = this.inputBackgroundColor;
          }
          if(this.checkIfRowExisits("hoursReportedDT") != undefined && this.checkIfRowExisits("hoursReportedDT").name === "hoursReportedDT"){
            document.getElementById("hoursReportedDT"+index)!.style.backgroundColor = this.inputBackgroundColor;
          }
        }
      }
    }
    return true;
  }
  checkIfRowExisits(name:any){
    return this.initColumnsData.find((obj:any) => {
      return obj.name === name;
    });
  }
  ssnValidationOnly(name: any,index:any,row:any){
    if(name == 'ssn'){
      if(row.ssn == null || row.ssn.replaceAll('-','').length != 9 || row.ssn == 'undefined' || row.ssn == '' || !Number(row.ssn.replaceAll('-',''))){
        //Not throwing alert this is here to fix validion bug since adding dashes to SSN
        document.getElementById(name+index)!.style.backgroundColor = 'lightcoral';
      }else{
        document.getElementById(name+index)!.style.backgroundColor = this.inputBackgroundColor;
      }
    }

  }
  ssnValidation(){
    // Check for Bad FIRST?LAST Names with same SSN
		if(this.isAffirmative(this.systemParameters[11].parameterValue1!)){
      var ssnChecker = new Map<string, number>();
      for(let i = 0; i < this.dataSource.data.length; i++){
        if(ssnChecker.get(this.dataSource.data[i].ssn!)){
          var errorMsg = '';
					var count = Number(ssnChecker.get(this.dataSource.data[i].ssn!));
          var ssn = this.dataSource.data[i].ssn!;
					var checkFName = this.dataSource.data[count].firstName!;
					var checkLName = this.dataSource.data[count].lastName!;
					if (this.dataSource.data[i].firstName?.trim() != checkFName.trim()) { //different names
						// alert("First Names Must Match For Same SSN:"+ssn+"\n'" + this.dataSource.data[i].firstName + "'\n'" +checkFName+"'");// throw error
            errorMsg = "First Names Must Match For Same SSN \n";
						document.getElementById("firstName"+i)!.style.backgroundColor = 'lightcoral';
            document.getElementById("firstName"+count)!.style.backgroundColor = 'lightcoral';
						// return false;
					}
					if (this.dataSource.data[i].lastName?.trim() != checkLName.trim()){
						// alert("Last Names Must Match For Same SSN:"+ssn+"\n'" + this.dataSource.data[i].lastName + "'\n'" +checkLName+"'");// throw error
            errorMsg += "Last Names Must Match For Same SSN \n";
						document.getElementById("lastName"+i)!.style.backgroundColor = 'lightcoral';
            document.getElementById("lastName"+count)!.style.backgroundColor = 'lightcoral';
						// return false;
					} 
          if(errorMsg.length === 0) {
						ssnChecker.set(this.dataSource.data[i].ssn!, i);
            document.getElementById("firstName"+i)!.style.backgroundColor = this.inputBackgroundColor;
            document.getElementById("firstName"+count)!.style.backgroundColor = this.inputBackgroundColor;
            document.getElementById("lastName"+i)!.style.backgroundColor = this.inputBackgroundColor;
            document.getElementById("lastName"+count)!.style.backgroundColor = this.inputBackgroundColor;
					}else{
            alert(errorMsg);
            return false;
          }
				}else{
					ssnChecker.set(this.dataSource.data[i].ssn!, i);
				}
      }
		}
    return true;
  }

  processFinalize(employerReport:any){
    this.userManagementService
      .getUserRemittanceReport(this.agreementId, this.employerId)
      .subscribe((response: any) => {
        this.employerReport = response.result.employerReport;
        this.employerReportService
          .finalizeReport(response.result.employerReport, this.erfPayment)
          .pipe(take(1))
          .subscribe((response: any) => {
            // this.loadData();
            this.pdfData = response;
            this.modalService.dismissAll();
            this.pdfButton = !this.pdfButton;
            this.processCalculatePdf();
            // let file = new Blob([response], { type: 'application/pdf' });
            // this.fileURL = URL.createObjectURL(file);
            // this.openPdf(this.pdfModal);
            // this.authService.refreshToken().subscribe((result)=> {}, () => {});
          });
      });
  }
  processUnfinalize(){
    // this.open(finalizeModal);
    //TODO:
    // if (confirm("By un-finalizing this report, this report could now be deleted or re-finalized. " + 
    //     "Any existing extract that contained this report or payment will not be modified.")) {
    //     console.log("You pressed OK!");
    //   }
  }
  resetPdfButton(){
    this.pdfButton = false;
  }
  processPayment(){
    this.pdfButton = !this.pdfButton;
    this.employerReportService.pdfCoupon(this.pdfData)
        .pipe(take(1))
        .subscribe((response:any) => {
          let file = new Blob([response], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          // saveAs(file,'WebERF-'+new Date()+'.pdf');
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
  }
  processCalculatePdf(){
    this.pdfButton = !this.pdfButton;
    this.employerReportService.pdfReport(this.pdfData)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
  }
  checkReportedEmployeesExceptions(){
    if(this.employerReport != null && this.employerReport.reportedEmployees != null){
      for(let ex of this.employerReport.reportedEmployees){
        if(ex.exceptions != null && ex.exceptions.length > 0){
          return true;
        }
      }
    }
    return false;
  }
  fixPayment(){
    for(let p of this.employerReport.payment){
      // console.log(p);
      // p.push({'employerReport':this.employerReport.guid})
      p.employerReport =this.employerReport.guid;
      // console.log(p);
    }
    this.fixReportedEmployeesExceptions();
  }
  fixReportedEmployeesExceptions(){
    if(this.employerReport.reportedEmployees != null){
      for(let ex of this.employerReport.reportedEmployees){
        if(ex.exceptions.length > 0){
          for(let r of ex.exceptions){
            r.reportedEmployee = ex.guid
          }
        }
      }
    }
    // console.log(this.employerReport)
  }
  eftCheck(p:any){
    if(this.employerInfo.allowEFT == 1
      && this.employerInfo.bankStatus == 3
      && this.employerInfo.bankAccountNumber != null
      && p.AllowEFT == 1
      && p.EFT_AccountNo_TO != null
      && this.isAffirmative(this.systemParameters[16].parameterValue1!)
      && p.erfPmtAmtDue != 0){
        return true;
    }
    return false
  }
  isEftColumnHidden(isField:boolean){
    if(!isField){
      return 'noscreen';
    }else{
      return 'nowrap'
    } 
  }
  isColumnHidden(name:any,isField:boolean){
    if(isField){
      var obj = this.formatFields.find((obj:any) => {
        return obj.erfField.fieldName === name;
      });
      if(obj == null){
        return 'noscreen';
      }else{
        return 'nowrap'
      } 
    } else {
      if(name === 'allowTravelHours'){
        if(!this.employerReport.erfFormat.allowTravelHours){
          return 'noscreen';
        }else{
          return 'nowrap'
        }
      }
    }
    return 'nowrap'
  }
  getColumnFundStats(fieldName:any){
    var total = 0;
    for(let efcd of this.employerReport.employerFundContributionsDue){
      if(fieldName === "contributionsDue"){
        total += efcd.contributionsDue;
      }else if(fieldName === "daysApplied"){
        total += efcd.daysApplied;
      }else if(fieldName === "hoursAppliedDT"){
        total += efcd.hoursAppliedDT;
      }else if(fieldName === "hoursAppliedOT"){
        total += efcd.hoursAppliedOT;
      }else if(fieldName === "hoursAppliedST"){
        total += efcd.hoursAppliedST;
      }else if(fieldName === "wages"){
        total += efcd.wages;
      }else if(fieldName === "weeksApplied"){
        total += efcd.weeksApplied;
      }
    }
    return total;
  }
  isEmployerOrStaff(){
    if(this.role == this.isRoleEmployer || this.role == this.isRoleEmployerStaff){
      return true;
    } else {
      return false;
    }
  }
  // finalizeOnClick(firstPayment:any,allowShort:any){
    finalizeOnClick(allowShort:any){
    var firstPayment = this.erfPayment[0] == undefined || this.erfPayment[0].erfPmtAmtDue == undefined ? 0 : this.erfPayment[0].erfPmtAmtDue;
    var paymentDetailsRows = this.erfPayment.length;
    for(var i=0; i<paymentDetailsRows; i++){
      const pmtTypeObj = document.getElementById("ERF_Pmt_Type"+i) as HTMLInputElement;
      var pmtType = pmtTypeObj;

      
      if(pmtType != null) {
        const eftCreditAmount = document.getElementById("eftCreditAmount") as HTMLInputElement;
        const pmtDateObj = document.getElementById("eftPmtDate"+i) as HTMLInputElement;
        var pmtDate = pmtDateObj;
        if(pmtDate.value != "" && pmtType.checked == false) {
          alert("Select the EFT checkbox or delete the payment date");
          return;
        }
        var rmk = this.employerReport.employerNotes;
        var crd;
        if(eftCreditAmount == null){
          crd = null;
        } else {
          crd = eftCreditAmount.value;
        }
        if(pmtType != null && pmtType.checked == true ) {
          if(pmtDate.value == "") {
            alert("Payment Date is required for EFT");
            return;
          }

          if(this.from_to_date(pmtDate.value, this.convertDate(new Date()))) {
            alert("Payment Date cannot be on or before current date");
            pmtDate.value = "";
            return;
          }

          if(this.from_to_date(pmtDate.value,this.convertDate(new Date(this.employerReport.workPeriodBegin)))) {
          // if(!this.from_to_date(this.convertDate(new Date(this.employerReport.workPeriodBegin)), pmtDate.value)) {
            alert("Payment Date cannot be before Report Period");
            pmtDate.value = "";
            return;
          }

          if(!this.from_to_date(this.convertDate(new Date(this.employerInfo.eftProcessDate)), pmtDate.value)) {
            alert("You may submit payments via EFT beginning "+new Date(this.employerInfo.eftProcessDate));
            pmtDate.value = "";
            return;
          }
          if (i == 0 && allowShort) {
            // var rmk = eval("document.frm.eftCreditNotes.value");
            // var crd = eval("document.frm.eftCreditAmount.value");
            if (crd == null){ crd = '0';};
            var amt = firstPayment;
            if (crd >= firstPayment) {
              alert("Credit amount must be less than amount due.");
              return;
            } else if (Number(crd) < 0) {
              alert("Credit amount must be a positive number.");
              return;
            }
            if (Number(crd) != 0 && rmk == "") {
              if (!confirm("You have not entered employer notes explaining the credit.\nDo you wish to continue anyway?")) {
                return;
              }
            }
          }
        }
        if (crd != null && Number(crd) != 0 && pmtType.checked == false) {
          alert("Credits can only be applied to EFT payments.");
          return;
        }
      
        
        if(pmtType == null || pmtType.checked == false) {
          this.erfPayment[i].ERF_Pmt_Type = 1;
          this.erfPayment[i].EFT_Pmt_Date = null;
          this.erfPayment[i].eftCreditAmount = 0;
        }else{
          this.erfPayment[i].ERF_Pmt_Type = 2;
          this.erfPayment[i].EFT_Pmt_Date = this.convertToSendDate(pmtDate.value);
          if (i==0 && eftCreditAmount != null && eftCreditAmount.value != null) {
            firstPayment = this.erfPayment[i].erfPmtAmtDue;
            this.erfPayment[i].eftCreditAmount = eftCreditAmount.value;
          } else {
            this.erfPayment[i].eftCreditAmount = 0;
          }
        }
        // }
      }else {
        this.erfPayment[i].ERF_Pmt_Type = 1;
        this.erfPayment[i].EFT_Pmt_Date = null;
        this.erfPayment[i].eftCreditAmount = 0;
      }
      if(this.erfPayment[i].eftCreditAmount == null || this.erfPayment[i].eftCreditAmount == undefined || this.erfPayment[i].eftCreditAmount == ''){
        this.erfPayment[i].eftCreditAmount = 0;
      }
    }

    if(confirm("A report cannot be modified after it has been 'finalized'. \nDo you want to finalize this report? "))
    {
      this.finalize = true;
      this.validation('FINALIZE')
      return;
    }
    else
      return false;
  }
  from_to_date(from_date:any,to_date:any){
    var new_to_date, new_from_date,first_dash,second_dash,mm,dd,yy ;
    if(!!from_date) {
      first_dash = from_date.indexOf("/");
      second_dash = from_date.lastIndexOf("/");
      mm = from_date.substring(0,first_dash);
      dd = from_date.substring(first_dash+1,second_dash);
      yy = from_date.substring(second_dash+1,from_date.length);
      new_from_date = new Date(yy,mm-1,dd);
    }

    if(to_date != "") {
      first_dash = to_date.indexOf("/");
      second_dash = to_date.lastIndexOf("/");
      mm =to_date.substring(0,first_dash);
      dd =to_date.substring(first_dash+1,second_dash);
      yy =to_date.substring(second_dash+1,to_date.length);
      new_to_date = new Date(yy,mm-1,dd);
    }
    if (!!new_from_date && !!new_to_date && new_from_date > new_to_date ) {
      //alert("Note:  Completion Date cannot be before Start Date");
      //to_date.focus();
      // to_date.select();
      return (false);
    }
    return (true);
  }
  convertDate(d:any){
    return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
  }
  convertToSendDate(str:any){
    var first_dash,second_dash,mm,dd,yy ;
    first_dash = str.indexOf("/");
    second_dash = str.lastIndexOf("/");
    mm =str.substring(0,first_dash);
    dd =str.substring(first_dash+1,second_dash);
    yy =str.substring(second_dash+1,str.length);
    var date = (yy.toString() + '-' + (Number(mm)-1).toString() + '-'+dd);
    // console.log(new Date(str));
    var finalDate = new Date(str);
    finalDate.setHours(12, 0, 0, 0); //set hours to 12pm since issue with timezone from clients
    return finalDate;
  }

  //Example filter out holidays:                                   https://www.itsolutionstuff.com/post/angular-material-datepicker-disable-specific-dates-exampleexample.html
  //Example for date range that will work with holiday exclusion : https://www.concretepage.com/angular-material/angular-material-datepicker-disable-past-and-future-dates
  public myDateFilter = (d: Date | null): boolean => {
    var minDate:Date = new Date();
    var maxDate: Date = new Date(this.employerReport.workPeriodBegin);
    // add EFT_CUTOFF_DAY
    maxDate.setDate(maxDate.getDate() + Number(this.systemParameters[19].parameterValue1) -1);
    // add EFT_CUTOFF_MONTHS
    maxDate.setMonth(maxDate.getMonth() + Number(this.systemParameters[20].parameterValue1));
    const date = d || new Date();

    //Add Holidays to
    var myHolidayDates:Date[] = [];
    for(let h of this.blankErfForm.holidays){
      myHolidayDates.push(new Date(h.holiday));
    }
    //Disable Weekends
    if(!this.isAffirmative(this.systemParameters[21].parameterValue1!)){
      return date >= minDate && date <= maxDate && d?.getDay() !== 0 && d?.getDay() !== 6 && !myHolidayDates.find(x=>x.getTime()==date.getTime());
    }else{
      return date >= minDate && date <= maxDate && !myHolidayDates.find(x=>x.getTime()==date.getTime());
    }
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    var dqDate;
    var achSubmitDate;
    var achDQDate;
    const date = cellDate;
    if(this.blankErfForm.dc != null){
      dqDate = this.blankErfForm.dc.paymentDelinquencyDate;
      achSubmitDate = this.blankErfForm.dc.submitAchByDate;
      achDQDate = this.blankErfForm.dc.achDelinquencyDate;
      if(dqDate != null){
        dqDate = new Date(dqDate);
        if(dqDate.getFullYear() === date.getFullYear() && dqDate.getMonth() === date.getMonth() && dqDate.getDate() === date.getDate()){
          this.dates.push({date: this.dateToString(dqDate), text: "Delinquency Date"});
          return new Date(dqDate) ? 'dqDateStyle' : 'example-custom-date';
        }
      }else if(dqDate != null && achSubmitDate != null){
        dqDate = new Date(dqDate);
        achSubmitDate = new Date(achSubmitDate);
        if(date.getMonth() == dqDate.getMonth() && date.getDate() > dqDate.getDate() && date.getFullYear() == dqDate.getFullYear() &&
        date.getMonth() == achSubmitDate.getMonth() && date.getDate() < achSubmitDate.getDate() && date.getFullYear() == achSubmitDate.getFullYear()){
          return new Date(dqDate) ? 'achSubmitGraceStyle' : 'example-custom-date';
        }
      }else if(achSubmitDate != null){
        achSubmitDate = new Date(achSubmitDate);
        if(date.getMonth() == achSubmitDate.getMonth() && date.getDate() == achSubmitDate.getDate() && date.getFullYear() == achSubmitDate.getFullYear()){
          this.dates.push({date: this.dateToString(achSubmitDate), text: "Submit ACH By Date"});
          return new Date(dqDate) ? 'achSubmitDateStyle' : 'example-custom-date';
        }
      }else if(achDQDate != null){
        achDQDate = new Date(achDQDate);
        if(date.getMonth() == achDQDate.getMonth() && date.getDate() == achDQDate.getDate() && date.getFullYear() == achDQDate.getFullYear()){
          this.dates.push({date: this.dateToString(achDQDate), text: "ACH Delinquency Date"});
          return new Date(dqDate) ? 'achDQStyle' : 'example-custom-date';
        }
      }
    }

    var minDate:Date = new Date();
    if(minDate.getFullYear() === date.getFullYear() && minDate.getMonth() === date.getMonth() && minDate.getDate() === date.getDate()){
      return 'example-custom-date-class';
    }

    // Only highligh dates inside the month view.
    if (view === 'month') {
      //Highlight Holidays light red
      for(let h of this.blankErfForm.holidays){
        var holiday = new Date(h.holiday);
        if(holiday.getFullYear() === date.getFullYear() && holiday.getMonth() === date.getMonth() && holiday.getDate() === date.getDate()){
          this.dates.push({date: this.dateToString(holiday), text: "Holiday!"});
          return 'example-custom-date-class';
        }
      }
    }
    return 'example-custom-date';
  };
  displayMonth() {
    let elements = document.querySelectorAll(".endDate");
    let x = elements[0].querySelectorAll(".mat-calendar-body-cell");
    x.forEach(y => {
      const dateSearch = this.dateToString(
        new Date(y.getAttribute("aria-label")!)
        // new Date(y.getAttribute("aria-label"))
      );
      const data = this.dates.find((f: { date: string; }) => f.date == dateSearch);
      if (data) y.setAttribute("aria-label", data.text);
    });
  }
  streamOpened(event:any) {
    setTimeout(() => {
      let buttons = document.querySelectorAll("mat-calendar .mat-icon-button");

      buttons.forEach(btn =>
        this.renderer.listen(btn, "click", () => {
          setTimeout(() => {
            //debugger
            this.displayMonth();
          });
        })
      );
      this.displayMonth();
    });
  }
  dateToString(date: any) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  updateRowData(event:any,name:any,index:any,row:any){
    // row[name] = parseFloat(event).toFixed(2);
    row[name] = event;

  }
  updateFundRowData(event:any,name:any,index:any,row:any){
    if(isNaN(Number(event))){
      row.fundDetails[name].original = event;
    }else{
      row.fundDetails[name].original = Number(event);
    }
  }
  updateSsnRowData(event:any,name:any,index:any,row:any){
    row.ssn = event.target.value.replaceAll('-', '');
  }
  snnFormatEditer(ssn:any){
    ssn.value = ssn.value.replaceAll('-', '');
  }
  decimalCount(number:any) {
    if(number != null){
      // Convert to String
      const numberAsString = number.toString();
      // String Contains Decimal
      if (numberAsString.includes('.')) {
        return numberAsString.split('.')[1].length;
      }
      // String Does Not Contain Decimal
      return 0;
    }
    return 0;
  }

  createAlert(msg:string){
    this.alertMsg = msg;
    this.open(this.alertModal);
  }
  foucsAfterAlert(){
    document.getElementById(this.focusField)?.focus();
    this.validationEnabled = true;
    this.alertMsg = '';
  }
  isVeba(name:any,index:any,row:any){
    return this.checkIfVeba(name);
  }
  isVebaChanged(name:any,index:any,row:any){
    // if(row.fundDetails[name].original != row.fundDetails[name].vebaDue){
    if(row.fundDetails[name].original != row.fundDetails[name].vebaCalc){
      return 'veba';
    }
    return 'noVeba';
  }

  checkIfVeba(name:any){
    const val = this.initColumnsData.find((obj:any) => {
      return obj.name === name;
    });
    if(val == undefined){ return false;}
    if(val.displayOnly === 'V' || val.displayOnly === 'Y'){
      return true;
    }
    return false;
  }

  saveBeforeImport(){
    this.importSelected = true;
    this.validation('SAVE');
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  saveNewmapping(){
    if(this.newMappingName == null || this.newMappingName == "" || this.newMappingName == undefined){
      alert("Please add new Mapping Name!");
    }else{
      this.importService
      .saveNewMapping(this.newMappingName, this.agreementColumnsSortOrder, this.agreementColumns,this.employer.guid,this.employerReport.agreement.masterAgreementGUID, this.employerReport.guid )
      .subscribe(
        (response: any) => {
          // console.log(response);
          //This is for newly saved reports
          this.allExistingMappings = response.result.allExistingMappings;
          this.importNamesList = response.result.importNamesList;
          const result = response.result.importNamesList.filter((obj: { name: any; }) => {
            return obj.name === this.newMappingName.toUpperCase();
          });
          this.defaultMapping = result[0].guid;
          alert("Mapping format has been saved.");
        },
        (error: HttpErrorResponse) => {
          // alert("Error while saving mapping: "+error.message);
          alert(error.error.reason);
        }
      );
    }
  }
  getMapping(){
    // console.log('this.agreementColumnsSortOrder');
    // console.log(this.agreementColumnsSortOrder);

    for(let i = 0; i < this.agreementColumns.length; i++){
      const result = this.allExistingMappings.filter((obj: { type: { guid: any; }; }) => {
        return obj.type.guid === this.defaultMapping;
      });

      if(this.initColumnsData[i].displayOnly == "Y" || result[i] == undefined || result[i].columnOrder == null){
        this.agreementColumnsSortOrder[i] = "";
      }else{
        this.agreementColumnsSortOrder[i] = result[i].columnOrder;
      }
    }
    // console.log(this.agreementColumnsSortOrder);
  }
  sortPreview(data:any){
    if(data != ""){
      return true;
    }
    return false;
  }
  checkImportFile(){
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if(file){
        this.currentFile = file;
        //send file to get headers and sort order if exists
        this.importService
          .getHeaders(this.currentFile,this.employerReport.agreement,this.employer,this.headerRow, this.employerReport.guid)
          .subscribe(
            (response: any) => {
              this.fileImportFormat = response.result.fileColumns;
              this.fileColumnsPreview = response.result.fileColumnsPreview;
              this.agreementColumns = response.result.agreementColumns;
              this.agreementColumnsSortOrder = response.result.agreementColumnsSortOrder;
              this.allExistingMappings = response.result.allExistingMappings;
              this.importNamesList = response.result.importNamesList;
              this.modalService.dismissAll();
              //Show Preview when only 1 mapping
              if(this.importNamesList.length == 1){
                this.defaultMapping = this.importNamesList[0].guid;
                this.getMapping();
                this.openPdf(this.importPreview);
              }else if(this.importNamesList != null && this.importNamesList.length > 0){
                this.defaultMapping = this.importNamesList[0].guid;
                this.getMapping();
                this.openPdf(this.importSetup);
              } else {
                this.openPdf(this.importSetup);
              }
            },
            (error: HttpErrorResponse) => {
              alert(error.error.reason);
            }
          );
        ///////
        // this.modalService.dismissAll();
        // this.openPdf(this.importSetup);
      }else{
        alert('Select a file to continue.');
      }
    }else{
      alert('Select a file to continue.');
    }
  }
  previewReport(){
    this.modalService.dismissAll();
    // console.log(this.agreementColumnsSortOrder);
    this.openPdf(this.importSetup);
  }
  importUpdateReport(){
    
    this.importedEmployees = [];

    this.importService
    .checkClassMapping(this.currentFile!,this.employerReport.agreement,this.employer,this.headerRow,this.defaultMapping,this.agreementId)
    .subscribe(
      (response: any) => {
        // console.log(response);
        this.hoursNotMapped = response.result.hoursNotMapped;
        this.jobsNotMapped = response.result.jobsNotMapped;
        this.employmentReasonNotMapped = response.result.employmentReasonNotMapped;
        this.workingLocalNotMapped = response.result.workingLocalNotMapped
        this.hoursToBeMapped = response.result.hoursToBeMapped;
        this.jobsToBeMapped = response.result.jobsToBeMapped;
        this.workingLocalToBeMapped = response.result.workingLocalNotMapped;
        this.employmentReasonToBeMapped = response.result.employmentReasonToBeMapped;
        if(this.hoursNotMapped.length > 0 || this.jobsNotMapped.length > 0 || this.employmentReasonNotMapped.length > 0){
          this.modalService.dismissAll();
          this.openPdf(this.employerClassMappingModal);
        }else{//All Good continue with import
          //SET REPORTED EMPLOYEES
          this.importService
          .importUpdateEmployerReport(this.currentFile!,this.employerReport.agreement,this.employer,this.headerRow,this.defaultMapping,this.agreementId)
          .subscribe(
            (response: any) => {
              // console.log(response);
              alert(response.result.message);
              this.modalService.dismissAll();
              this.loadData();
            },
            (error: HttpErrorResponse) => {
              // console.log(error.error.message);
              this.importReady = false;
              alert(error.error.reason);
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error.error.message);
        this.importReady = false;
        alert(error.error.reason);
      }
    );
  }
  ssnFormater(data:any){
    if(data == null || data == ""){ return data;}
    if(data.length < 3){
      return data;
    } else if(data.length < 5){
      return  data.slice(0,3) + '-' + data.slice(3,5);
    } else {
      return  data.slice(0,3) + '-' + data.slice(3,5) + '-' + data.slice(5);
    }
  }
  hoursClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.hoursToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  jobClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.jobsToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  workLocalClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.workingLocalToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  employmentReasonClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.employmentReasonToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  saveClassMapping(){
    for(let i = 0; i < this.hoursNotMapped.length; i++){
      if(this.hoursNotMapped[i].idFinder == null){
        alert("All mappings must have an assigned value before import can continue.");
        return;
      }
    }
    for(let i = 0; i < this.employmentReasonNotMapped.length; i++){
      if(this.employmentReasonNotMapped[i].idFinder == null){
        alert("All mappings must have an assigned value before import can continue.");
        return;
      }
    }
    // TODO: add jobclass
    //CONTINUE TO SAVE MAPPING THEN DO IMPORT
    this.importService
    .saveClassMapping(this.hoursNotMapped,this.employmentReasonNotMapped,this.jobsNotMapped)
    .subscribe(
      (response: any) => {
        // console.log(response);
        alert(response.result.message);
        this.modalService.dismissAll();
        this.openPdf(this.importPreview);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.error.message);
        alert(error.error.reason);
      }
    );
  }
  clearEmploymentStatus(data:any) {
    data.emplStatusReasonGuid = null;
    data.emplStatusDate = null;
  }
  previewData(value:any,index:any){
    if(Number(value) && index > 1){
      if(this.initColumnsData[index].name == "emplStatusDate" ){
        var date = new Date(Math.round((value - 25569) * 86400 * 1000));
        date.setDate(date.getDate() + 1);
        return date.getMonth()+1 + '/' +date.getDate()+'/'+date.getFullYear();
      }
      return Number(value).toFixed(2);
    }
    return value;
  }
  isDisplayOnly(column:any){
    // console.log(column);
    const val = this.initColumnsData.find((obj:any) => {
      return obj.display === column;
    });
    if(val == undefined || val == null || val == ''){ return false;}
    if(val.displayOnly === 'Y'){
      return true;
    }
    return false;
  }
  deleteImportMapping(){
    // console.log('this.defaultMapping');
    // console.log(this.defaultMapping);

    this.importService
      .deleteEmployerImportMapping(this.defaultMapping,this.employer,this.employerReport.agreement)
      .subscribe(
        (response: any) => {
          alert('Mapping format deleted.');
          this.modalService.dismissAll();
          this.allExistingMappings = response.result.allExistingMappings;
          this.importNamesList = response.result.importNamesList;
          if(this.importNamesList != null && this.importNamesList.length > 0){
            this.defaultMapping = this.importNamesList[0].guid;
          } else {
            this.defaultMapping = null;
          }
          this.getMapping();
          this.openPdf(this.importSetup);
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }

  setDefaultValues(){
    //TODO: FIX ME Add other default like objects
    //Set Hours Class default
    if(this.defaultHoursClassGuid != null && this.defaultHoursClassGuid != undefined && this.defaultHoursClassGuid != ''){
      const val = this.blankErfForm.allowedHoursClasses.find((obj:any) => {
        return obj.guid === Number(this.defaultHoursClassGuid);
      });
      this.dataSource.data[this.dataSource.data.length-1].hoursClass = val;
    }
    for(var val of this.dataSource.data){
      if(val.jobClass == null) {
        val.jobClass = {guid: null, parameterDesc: '-'};
      }
      if(val.hoursClass == null) {
        val.hoursClass = {guid: null, parameterDesc: '-'};
      }
      if(val.workingLocal == null) {
        // val.workingLocal = {guid:null};
      }
    }
  }
  selectTarget(event: Event) {
    const obj = (event.target as HTMLInputElement).select();
  }
}