<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>
    <mat-card>
        <p>
            Search By ID or Name, double click on Employer
        </p>
        <table>
            <tr>
                <mat-form-field class="example-full-width" appearance="fill" color="primary">
                    <mat-label>Employer ID</mat-label>
                    <input matInput [(ngModel)]="searchValueId" (ngModelChange)=SearchId()>
                </mat-form-field>

                OR

                <mat-form-field class="example-full-width" appearance="fill" color="primary">
                    <mat-label>Employer Name</mat-label>
                    <input matInput [(ngModel)]="searchValueName" (ngModelChange)=SearchName()>
                </mat-form-field>
            </tr>
            <!-- <tr>
          OR
        </tr>
        <tr>
          <label>Select Employer: </label>
          <select [(ngModel)]="selectedValue">
            <option value="">Select an Employer...</option>
            <option *ngFor="let emp of employerList" [ngValue]="listItem">
              {{ emp.er_NAME }}
            </option>
          </select>
        </tr> -->
        </table>
    </mat-card>

    <mat-card>

        <mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event" aria-label="Select page">
        </mat-paginator>

        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="er_ID">
                <mat-header-cell *matHeaderCellDef> Employer ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.organizationID}} </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="er_NAME">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.organizationName}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="hoverRow" (dblclick)="goToEmployerAgreement(row)"></mat-row>
        </mat-table>
    </mat-card>
</body>

<!-- Edit a Registered Employer Modal -->
<ng-template #editEmployerModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Setup/Edit Employer Processing Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <p>Use the following options to setup&#47;edit employer details. Fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required.</p> 

        <h5>Employer Details</h5>
        <div class="section" style="text-align: -webkit-center;">
            <table class="sectionData">
                <tr>
                    <td class="firstColumn"><span class="required">Employer ID Number*:</span></td>
                    <td>
                        {{selectedEmployer.organizationID}}
                        <!-- <input size="25" maxlength="20" title="Employer Identification Number (No dashes)" [(ngModel)]="selectedEmployer" /> -->
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Contractor License Number:</td>
                    <td>
                        {{selectedEmployer.licenseNumber}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Federal ID Number</td>
                    <td>
                        <ng-container *ngIf="selectedEmployer.federalEIN != null">
                            {{selectedEmployer.federalEIN | slice:0:2}}-{{selectedEmployer.federalEIN | slice:2}}
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn"><span class="required">Employer (Contractor) Name*:</span></td>
                    <td>
                        {{selectedEmployer.organizationName}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 1:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine1: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 2:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine2: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 3:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine3: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">City:</td>
                    <td>
                        {{employerAddress ? employerAddress.city: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">State/Province:</td>
                    <td>
                        {{employerAddress ? employerAddress.state: null}} 
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Zip/Postal Code:</td>
                    <td>
                        {{employerAddress ? employerAddress.zipPostalCode: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Country:</td>
                    <td>
                        {{employerAddress ? employerAddress.country: null}} 
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Area Code and Phone Number:</td>
                    <td>
                        {{(employerPhone && employerPhone.phoneNumber) ? ((employerPhone.phoneNumber.areaCode + employerPhone.phoneNumber.localNumber) | phoneNumber) : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Extension:</td>
                    <td>
                        {{(employerPhone && employerPhone.phoneNumber) ? employerPhone.phoneNumber.extension : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Fax Number:</td>
                    <td>
                        {{(employerPhone && employerPhone.faxNumber) ? ((employerPhone.faxNumber.areaCode + employerPhone.faxNumber.localNumber) | phoneNumber) : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Type of Business</td>
                    <td>
                        <!-- {{selectedEmployer.}} -->
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">First and Last Name of Payroll Contact:</td>
                    <td>
                        <!-- {{selectedEmployer.}} -->
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Payroll Contact Title:</td>
                    <td>
                        <!-- {{selectedEmployer.}} -->
                    </td>
                </tr>
            </table>
        </div>

        <h5>Fiscal Year</h5>
        <div class="section">
            <div class="sectionData">
                <p>This employer's fiscal year begins in:</p>
                <p>
                    <select [(ngModel)]="selectedEmployer.employerInfo.fiscalYearStart">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </p>
            </div>
        </div>

        <!-- TODO: get sys param -->
        <!-- <ng-container *ngIf="allowEft">

            <h5>Allow Payments Via EFT</h5>
            <div class="section">
                <div class="sectionData">
                    <p>Is this employer allowed to make payments via EFT (Electronic Funds Transfer) when applicable?</p>
                    TODO: add radion buttons
                </div>
            </div>
    
            <h5>EFT Pre-Note Status</h5>
            <div class="section">
                <div class="sectionData">
                    <p>This employer's current EFT Pre&#45;Note Status is:</p>
                    TODO: set value
                    <ng-container *ngIf="prenoteStatus() == 1">
                        <span class="bold">No EFT account set</span>
                    </ng-container>
                    <ng-container *ngIf="prenoteStatus() == 2">

                    </ng-container>
                    <ng-container *ngIf="prenoteStatus() == 3">

                    </ng-container>
                    <ng-container *ngIf="prenoteStatus() == 4">

                    </ng-container>
                    <ng-container *ngIf="prenoteStatus() == 5">

                    </ng-container>

                </div>
            </div>
        </ng-container> -->



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Yes</button>
    </div>
</ng-template>