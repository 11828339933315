
<!-- <button (click)="open(idleModal)">test idle</button> -->
<!-- <div class="content mat-app-background " [ngClass]="[setMode ? 'darkMode' : '']"> -->
<!-- <div class="content mat-app-background darkMode" > -->
<div class="content mat-app-background flex-wrapper" >
	<app-header ></app-header>
	<!-- The routed views render in the <router-outlet>-->
    <div class="mainContent">
        <router-outlet></router-outlet>
    </div>
	<!-- <router-outlet></router-outlet> -->
	<app-footer></app-footer>
</div>

<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"> -->
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">


<!-- IDLE Modal -->
<ng-template #idleModal let-modal>
    <div class="modal-header" style="justify-content: center;">
        <h4 class="modal-title" id="modal-basic-title">You Have Been Idle!</h4>

    </div>
    <div class="modal-body" style="text-align: -webkit-center;">
        For security purposes, you will soon be logged out. Any unsaved work will be lost. 
    </div>
    <div class="modal-footer">
        <button type="button" (click)="modal.close('Save click');logout();resetAlertCounter();" class="btn btn-danger">Logout</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');refresh();resetAlertCounter();">Stay Logged In</button>
    </div>
</ng-template>

<!-- Logout after IDLE Modal -->
<ng-template #loggedOutModal let-modal>
    <div class="modal-header" style="justify-content: center;">
        <h4 class="modal-title" id="modal-basic-title">You Have Been Logged Out!</h4>

    </div>
    <div class="modal-body" style="text-align: -webkit-center;">
        You were logged out for security reasons. Unsaved  work was not retained. 
    </div>
    <div class="modal-footer">
        <button type="button"  color="primary" mat-raised-button (click)="modal.close('Save click');">OK</button>
    </div>
</ng-template>