<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>    
    <h3 *ngIf="employer">
        <ng-container *ngIf="isEmployerOrStaff()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
    </h3>
    <span class="pageHeader">Staff Management</span>

    <div>
        <div>
            <mat-form-field style="float: left;" appearance="outline">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Username / First Name / Last Name / Email" #input>
            </mat-form-field>
            <button style="float:right;bottom:10px;" type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="selectedUser = {username:null,firstName:null,lastName:null,emailAddress:null,inactive:0};open(createModal)">Create New Staff</button> 
        </div> 
        <label style="color: red;float: left;">Locked means user cannot access WebERF.</label>
        <mat-table [dataSource]="employeeMapping" class="mat-elevation-z8" matSort>

            <!-- Username Column -->
            <ng-container matColumnDef="username">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Username </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> {{element.username}} </mat-cell>
            </ng-container>
        
            <!-- First Name Column -->
            <ng-container matColumnDef="firstname">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> First Name </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> 
                    <ng-container *ngIf="element.firstName != null && element.firstName != 'null'">
                        {{element.firstName}} 
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container matColumnDef="lastname">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Last Name </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> 
                    <ng-container *ngIf="element.lastName != null && element.lastName != 'null'">
                        {{element.lastName}} 
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Email </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> 
                    <ng-container *ngIf="element.emailAddress != null && element.emailAddress != 'null'">
                        {{element.emailAddress}} 
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Locked Column -->
            <ng-container matColumnDef="inactive">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Locked </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> 
                    <ng-container *ngIf="element.inactive == 1">
                        Locked
                    </ng-container>
                    <!-- {{element.inactive}}  -->
                </mat-cell>
            </ng-container>

            <!-- Last Column -->
            <ng-container matColumnDef="lastlogin">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Last Login </mat-header-cell>
                <mat-cell class="mainColumns" *matCellDef="let element"> 
                    <ng-container *ngIf="element.lastLogin == null">
                        Awaiting password update
                    </ng-container>
                    <ng-container *ngIf="element.lastLogin != null">
                        {{element.lastLogin | date: 'M/d/yy'}} at {{element.lastLogin | date: 'shortTime'}} 
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="deleteColumns"> Edit Details </mat-header-cell>
                <mat-cell class="deleteColumns" *matCellDef="let element"> 
                    <button mat-icon-button color="primary" title="Edit User Info" (click)="selectedUser = element;open(editModal)">
                        <mat-icon>edit</mat-icon>
                    </button>    
                </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [length]="employeeMapping.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event" aria-label="Select page">
        </mat-paginator>
    </div>
</body>

<!-- Edit Modal -->
<ng-template #editModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Staff Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table>
            <tr>
                <td>
                    <strong>Username:</strong>
                </td>
                <td></td>
                <td>
                    <strong>Locked/Inactive:</strong>
                </td>
            </tr>
            <tr>
                <td>
                    <input name="username" title="Username" maxlength="50"  [(ngModel)]="selectedUser.username" disabled="true" readonly/>
                </td>
                <td></td>
                <td>
                    <mat-checkbox color="primary" class="example-margin" [(ngModel)]="selectedUser.inactive">
                        <ng-container *ngIf="selectedUser.inactive == 0">
                            FALSE
                        </ng-container>
                        <ng-container *ngIf="selectedUser.inactive == 1">
                            TRUE
                        </ng-container>
                    </mat-checkbox>
                </td>
            </tr>
            <tr>
                <td>
                    <strong>First Name:</strong>
                </td>
                <td>
                    <strong>Last Name:</strong>
                </td>
                <td>
                    <strong>Email:</strong>
                </td>
            </tr>
            <tr>
                <td>
                    <input name="firstName" title="First Name" maxlength="20"  [(ngModel)]="selectedUser.firstName"/>
                </td>
                <td>
                    <input name="lastName" title="Last Name" maxlength="25"  [(ngModel)]="selectedUser.lastName"/>
                </td>
                <td>
                    <input name="emailAddress" title="Email Address" maxlength="50"  [(ngModel)]="selectedUser.emailAddress"/>
                </td>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button style="left:16px;position:absolute;" type="button" color="warn" title="This will generate a random password." mat-raised-button class="btn btn-outline-dark" (click)="resetUserPassword()">Reset Password</button>
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="updateUser();">Update</button>
    </div>
</ng-template>

<!-- Reset Modal -->
<ng-template #resetPassModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <ng-container *ngIf="isReset">Reset Staff Password</ng-container>
            <ng-container *ngIf="!isReset">New Staff Password</ng-container>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="isReset"><label>Password reset for username: {{selectedUser.username}}</label></ng-container>
        <ng-container *ngIf="!isReset"><label>Password for username: {{selectedUser.username}}</label></ng-container>
        <br>
        <label>Password is: {{selectedUserRandomPass}}</label>
        
        <button mat-icon-button color="primary" title="Copy to clipboard" [cdkCopyToClipboard]="selectedUserRandomPass">
            <mat-icon>library_books</mat-icon>
        </button>  
    </div>
    <div class="modal-footer">
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>

<!-- Create Modal -->
<ng-template #createModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create New Staff</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table>
            <tr>
                <td>
                    <strong>Username:</strong>
                </td>
                <td></td>
                <td>
                    <strong>Locked/Inactive:</strong>
                </td>
            </tr>
            <tr>
                <td>
                    <input name="username" title="Username" maxlength="50"  [(ngModel)]="selectedUser.username"/>
                </td>
                <td></td>
                <td>
                    <mat-checkbox color="primary" class="example-margin" [(ngModel)]="selectedUser.inactive">
                        <ng-container *ngIf="selectedUser.inactive == 0">
                            FALSE
                        </ng-container>
                        <ng-container *ngIf="selectedUser.inactive == 1">
                            TRUE
                        </ng-container>
                    </mat-checkbox>
                </td>
            </tr>
            <tr>
                <td>
                    <strong>First Name:</strong>
                </td>
                <td>
                    <strong>Last Name:</strong>
                </td>
                <td>
                    <strong>Email:</strong>
                </td>
            </tr>
            <tr>
                <td>
                    <input name="firstName" title="First Name" maxlength="20"  [(ngModel)]="selectedUser.firstName"/>
                </td>
                <td>
                    <input name="lastName" title="Last Name" maxlength="25"  [(ngModel)]="selectedUser.lastName"/>
                </td>
                <td>
                    <input name="emailAddress" title="Email Address" maxlength="50"  [(ngModel)]="selectedUser.emailAddress"/>
                </td>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="createUser();" [disabled]="selectedUser.username == null || selectedUser.username == undefined || selectedUser.username.trim().length == 0">Create</button>
    </div>
</ng-template>