<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="employer" class="pBody">    
    <h3>
        <ng-container *ngIf="isEmployerOrStaff() && !isPayRoll()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff()  && !isPayRoll()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isPayRoll()">
            Payroll Company
        </ng-container>
    </h3>
    <span class="pageHeader">Payroll Import</span>
    <mat-grid-list cols="4" rowHeight="50px">
        <mat-grid-tile
            *ngFor="let tile of tiles"
            [colspan]="tile.cols"
            [rowspan]="tile.rows"
            [style.background]="'lightgrey'">
            <!-- [style.background]="tile.color" -->
            <!-- select file area -->
            <ng-container *ngIf="tile.text == 'One'">
                <div class="multFileSelector">
                    <div>
                        <form>
                            <input type="file" [id]="'fileControl'" class="disabledSelector" (change)="save($event)" multiple="multiple" [disabled]="displayedColumns.includes('action1') && employerReports.data.length > 0">
                        </form>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="tile.text == 'Two'">
                <ng-container *ngIf="!displayedColumns.includes('action1') && selectedFiles != null && selectedFiles != undefined && selectedFiles.length > 0">
                    <button class="spaceBtns raisebtn importBtn" color="primary" mat-raised-button matTooltip="Run Payroll Import" (click)="runImport(false)">Run Payroll Import</button>
                    <button class="spaceBtns raisebtn importByPayBtn" color="primary" mat-raised-button matTooltip="Run Payroll Import" (click)="checkByPayPeriod()">Run Payroll Import By Pay Period</button>
                </ng-container>
                <!-- only show when there is employerReports -->
                <ng-container *ngIf="displayedColumns.includes('action1') && employerReports.data.length > 0">
                    <div class="deleteDiv">
                        <button class="spaceBtns raisebtn viewFiles" color="primary" mat-raised-button matTooltip="View Imported Files" (click)="openPdf(viewFilesModal)">View Imported Files</button>
                        <ng-container *ngIf="massCouponAvaliable">
                            <button class="spaceBtns raisebtn viewMassPDF" title="Mass Payroll Coupon" mat-raised-button color="primary" (click)="showMassCoupon()">Mass Payroll Coupon</button> 
                        </ng-container>
                        <button class="spaceBtns raisebtn finalBtn" color="primary" mat-raised-button matTooltip="Finalize all reports" (click)="openPdf(finalizeAllModal)">Finalize All</button>
                        <button class="spaceBtns raisebtn calcBtn" color="primary" mat-raised-button matTooltip="Calculate all reports" (click)="stayAlive();massCalculate()">Calculate All</button>
                        <button class="spaceBtns raisebtn deleteBtn" color="warn" mat-raised-button matTooltip="Delete all reports related to this import" (click)="openPdf(deleteAllModal);">Delete All</button>
                    </div>
                    
                </ng-container>
            </ng-container>
            <!-- Employer Reports data list -->
            <ng-container *ngIf="tile.text == 'Three'">
                <div class="table-container">
                    <mat-table [dataSource]="employerReports" class="mat-elevation-z8">                    
                        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns;let i = index">
                            <ng-container *ngIf="column == 'agreement'">
                              <mat-header-cell *matHeaderCellDef> Agreement </mat-header-cell>
                              <mat-cell *matCellDef="let element"> {{element.agreement.agreementLongName}} </mat-cell>
                              <mat-footer-cell *matFooterCellDef> Totals </mat-footer-cell>
                            </ng-container>
                            <ng-container *ngIf="column == 'action1'">
                                <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                                <mat-cell *matCellDef="let element;let j = index"> 
                                    <button title="Edit report" mat-button color="primary" (click)="goToLink(element)">
                                        <mat-icon>create</mat-icon>
                                    </button>
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
                            </ng-container>
                            <ng-container *ngIf="column == 'action2'">
                                <mat-header-cell *matHeaderCellDef> View PDF </mat-header-cell>
                                <mat-cell *matCellDef="let element;let j = index"> 
                                    <button title="Coupon and Report PDF" mat-button color="primary" (click)="getPdf(element)">
                                        <mat-icon>picture_as_pdf</mat-icon>
                                    </button> 
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
                            </ng-container>
                            <ng-container *ngIf="column == 'Hours'">
                                <mat-header-cell *matHeaderCellDef> {{column}} </mat-header-cell>
                                <mat-cell *matCellDef="let element;let j = index"> {{reportTotals[j][i-1]}} </mat-cell>
                                <mat-footer-cell *matFooterCellDef="let element;let j = index"> {{getTotalCost(i-1)}} </mat-footer-cell>
                            </ng-container>
                            <ng-container *ngIf="column == 'totalSum'">
                                <mat-header-cell *matHeaderCellDef> Total Contributions </mat-header-cell>
                                <mat-cell *matCellDef="let element;let j = index"> {{getReportTotal(j) | currency}} </mat-cell>
                                <mat-footer-cell *matFooterCellDef="let element;let j = index"> {{getAllReportTotal() | currency}} </mat-footer-cell>
                            </ng-container>
                            <ng-container *ngIf="column != 'agreement' && column != 'action1' && column != 'action2' && column != 'Hours' && column != 'totalSum'">
                                <mat-header-cell *matHeaderCellDef> {{column}} </mat-header-cell>
                                <mat-cell *matCellDef="let element;let j = index"> {{reportTotals[j][i-1] | currency}} </mat-cell>
                                <mat-footer-cell *matFooterCellDef="let element;let j = index"> {{getTotalCost(i-1) | currency}} </mat-footer-cell>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="employerReports == null || employerReports.data.length <=0">
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                                    <label>No payroll file selected. Choose file(s) above; then click "Run Payroll Import".</label><br>
                                    <label>This process may take several minutes, depending on the number of files and their size.</label><br>
                                    <label>Do not leave this page or close the window while this process is running.</label>
                                </td>
                            </tr>
                        </ng-container>
                      
                        <mat-header-row *matHeaderRowDef="columnsToDisplay;sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
                        <mat-footer-row *matFooterRowDef="columnsToDisplay;sticky: true"></mat-footer-row>
                    </mat-table>
                </div>
            </ng-container>
        </mat-grid-tile>
      </mat-grid-list>
</body>

<!-- PDF Modal -->
<ng-template #pdfModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Computation of Payments</h1>
    </div>
    <div class="modal-header" style="white-space: nowrap;">
        <div style="float: left;">
            <ng-container *ngIf="!showingMassCoupon">
                <ng-container *ngIf="!pdfButton; else pdfBtn">
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processPayment();">Payment Coupon</button>
                </ng-container>
                <ng-template #pdfBtn>
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processReport();">Calculated Report</button>
                </ng-template>
            </ng-container>
        </div>
        <div style="float: right;">
            <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');" style="margin-left:5px">Close</button>
        </div>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/pdf" width="100%" height="800px" ></object>
        </p>
    </div>
</ng-template>

<!-- View Files Modal -->
<ng-template #viewFilesModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Imported Files</h1>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="overflow-y: auto;height: 400px;">
        <ng-container *ngFor="let f of massImportFiles;let i = index">
            <label>{{f.fileName}}</label><br>
        </ng-container>
    </div>
</ng-template>

<!-- Delete All Modal -->
<ng-template #deleteAllModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Delete All Reports</h1>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete all reports?
    </div>
    <div class="modal-footer">
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="warn" mat-raised-button class="btn btn-outline-dark" (click)="modal.dismiss('Cross click');deleteAll()">Delete All</button>
    </div>
</ng-template>

<!-- Finalize All Modal -->
<ng-template #finalizeAllModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Finalize All Reports</h1>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>The below options will apply to all reports created from the payroll import.</label> <br>
        <p class="defaults"><strong>Make Payment(s) As Follows:</strong></p>
        <table>
            <!-- Header -->
            <tr class="pmtTable" style="border: solid;">
                <th [ngClass]="isEftColumnHidden(eftCheck(erfPayment[0][0]))" style="border: solid;">EFT</th>
                <th class="pmtTable" style="border: solid;">Payment</th>
                <th class="pmtTable" style="border: solid;">Payable To</th>
                <th class="pmtTable" style="border: solid;">Total Amount</th>
                <th [ngClass]="isEftColumnHidden(eftCheck(erfPayment[0][0]))" style="border: solid;">Payment Date</th>
                <th class="pmtTable" style="border: solid;">Instructions</th>
            </tr>
            <!-- Contents -->
            <tr class="pmtTable" style="border: solid;">
                <td [ngClass]="isEftColumnHidden(eftCheck(erfPayment[0][0]))" style="border: solid;">
                    <ng-container *ngIf="eftCheck(erfPayment[0][0]);else eftpmt">
                        <input [id]="'ERF_Pmt_Type'" type="checkbox" class="center" />
                    </ng-container>
                    <ng-template #eftpmt>
                        <!-- <input type="checkbox" class="center" name="ERF_Pmt_Type{{i}}" /> -->
                    </ng-template>
                </td>
                <td class="pmtTable" style="border: solid;">{{erfPayment[0][0].PmtDesc}}</td>
                <td class="pmtTable" style="border: solid;"><strong>{{erfPayment[0][0].PmtPayableTo}}</strong>&nbsp;</td>
                <td class="pmtTable" style="border: solid;"><strong>{{getTotalAmount() | currency}}</strong>&nbsp;</td>
                <td [ngClass]="isEftColumnHidden(eftCheck(erfPayment[0][0]))" style="border: solid;">
                    <ng-container *ngIf="eftCheck(erfPayment[0][0]);else eftpmtdate">
                        <p>
                            <mat-form-field color="primary" appearance="fill">
                            <mat-label>Payment Date</mat-label>
                            <input [id]="'eftPmtDate'" matInput [matDatepicker]="dp3" [matDatepickerFilter]="myDateFilter" disabled>
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker (opened)="streamOpened($event)" [dateClass]="dateClass" #dp3 disabled="false" color="primary" panelClass="endDate"></mat-datepicker>
                            </mat-form-field>
                        </p>
                    </ng-container>
                    <ng-template #eftpmtdate>
                        <!-- <input type="checkbox" class="center" name="ERF_Pmt_Type{{i}}" /> -->
                        <!-- <input type="checkbox" class="center" /> -->
                    </ng-template>
                </td>
                <td class="pmtTable" style="border: solid;">
                    {{erfPayment[0][0].PmtInstruction==null?"":erfPayment[0][0].PmtInstruction}}&nbsp;
                    <ng-container *ngIf="erfPayment[0][0].PmtInstruction==null && !isEmployerOrStaff();else pmtinst"></ng-container>
                    <ng-template #pmtinst>
                        <div class="link" [innerHTML]="systemParameters[0].parameterValue1"></div>
                    </ng-template>
                </td>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" 
        (click)="finalizeOnClick();modal.close('Save click')">Finalize All</button>
    </div>
</ng-template>

<!-- By Pay Period Modal -->
<ng-template #byPayPeriodModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Pay Period</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <select (change)="selectPayPeriod($event);" name="selectMapping">
            <option>--Select--</option>
            <option *ngFor="let period of byPayPeriodDates">
                {{ period | date : 'longDate'}}
            </option>
        </select>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button  class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="byPayPeriod()">Run Import By Pay Period</button>
    </div>
</ng-template>

<!-- Select Reporting period -->
<ng-template #selectByPayPeriodModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Reporting Period</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <mat-form-field class="cal2Width" color="primary" appearance="fill" >
            <mat-label>Reporting Period From</mat-label>
            <input matInput [matDatepicker]="picker2" [readonly]="true" [(ngModel)]="reportDate.dateString" [min]="minDate.dateString"
                [max]="maxDate.dateString">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field> -->
        <mat-form-field class="cal1Width">
            <input matInput [matDatepicker]="dp" [formControl]="date" [readonly]="true"
                [min]="minDate.dateString" [max]="maxDate.dateString">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
            </mat-datepicker>
        </mat-form-field>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="byPayPeriodGetDates()">Proceed</button>
    </div>

</ng-template>