<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>

<body>
    <h3>
        Webmaster
    </h3>
    <span class="pageHeader">Announcements</span>

    <p class="topParagraph">Select the type of announcement from the options below and then click the Create button to create a new announcement.</p>
    <div class="topDiv">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group radioGroup" [(ngModel)]="announcementType">
            <!-- <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">
                {{season}}
            </mat-radio-button> -->
            <mat-radio-button [value]="1" color="primary">Global Announcement</mat-radio-button>
            <mat-radio-button [value]="2" color="primary">Agreement-Specific Announcement</mat-radio-button>
            <mat-radio-button [value]="3" color="primary">Employer-Specific Announcement</mat-radio-button>
        </mat-radio-group>
        <button class="createBtn" mat-raised-button color="primary" (click)="clearData();open(createAnnouncement)">Create</button>
    </div>
    <div class="bottomDiv">
        <span class="pageHeader">Existing Announcements</span>
        <p class="topParagraph">To edit an existing announcement, double-click the row within the list.</p>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8 existingAnnouncements">

            <!-- # Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                <mat-cell *matCellDef="let element;let i = index">
                    {{i+1}}
                </mat-cell>
            </ng-container>
            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Announcement Title </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.announcement.header}}
                </mat-cell>
            </ng-container>
            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{typeName(null,element.mid)}}
                </mat-cell>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{getStatus(element)}}
                    <!-- {{element.active ? "Active":"Inactive"}} -->
                </mat-cell>
            </ng-container>
            <!-- Effective Date Column -->
            <ng-container matColumnDef="eff">
                <mat-header-cell *matHeaderCellDef> Effective Date </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.announcement.effDate | date:'MM/dd/YYYY'}}
                </mat-cell>
            </ng-container>
            <!-- Expiration Date Column -->
            <ng-container matColumnDef="exp">
                <mat-header-cell *matHeaderCellDef> Expiration Date </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.announcement.expDate | date:'MM/dd/YYYY'}}
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row><!--sticky: true //removed for visual fix with modal-->
            <mat-row class="rowHover" *matRowDef="let row; columns: displayedColumns;" (dblclick)="editAnnouncement(row)"></mat-row>
        </mat-table>
    </div>
</body>

<!-- Create Announcement Modal -->
<ng-template #createAnnouncement let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create/Edit Announcement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Be sure to preview your announcement and check capitalization, spelling and grammar before saving.&nbsp;
            Fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required.
        </p>
        <table>
            <tr>
              <td><label for="type">Announcement Type:</label> {{typeName(announcementType,null)}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            <tr>
              <td><label for="title">Announcement Title<span class="required">*</span>:</label><br />(maximum of 300 characters allowed)</td>
            </tr>
            <tr>
              <td>
                <textarea name="annTxtHeader" cols="135" rows="3" id="title" title="Title for the announcement" [(ngModel)]="announcementText.header">{{announcementText.header}}</textarea>
                <!-- <%=announcementText.getAnnTxtHeader()==null ?"":announcementText.getAnnTxtHeader()%> -->
                </td>
            </tr>
            <tr>
              <td><label for="text">Announcement Text<span class="required">*</span>:</label><br />(maximum of 4000 characters allowed)</td>
            </tr>
            <tr>
                <td>
                    <textarea name="annTxtMessage" cols="135" rows="10" id="text" title="Text of the announcement" [(ngModel)]="announcementText.message">{{announcementText.message}}</textarea>
                    <!-- <%=announcementText.getAnnTxtMessage()==null ?"":announcementText.getAnnTxtMessage()%> -->
                </td>
            </tr>
            <tr>
                <td><label for="footer">Announcement Footer:</label><br />(maximum of 300 characters allowed)</td>
            </tr>
            <tr>
                <td>
                    <textarea name="annTxtFooter" cols="135" rows="3"  id="footer" title="Additional information such as contact information" [(ngModel)]="announcementText.footer">{{announcementText.footer}}</textarea>
                    <!-- <%=announcementText.getAnnTxtFooter()==null?"":announcementText.getAnnTxtFooter()%> -->
                </td>
            </tr>
            <tr>
                <td><label for="URL">Display Name of Associated File or URL:</label><br />(maximum of 300 characters allowed)</td>
            </tr>
            <tr>
                <td>
                    <textarea name="annTxtUrlTitle" cols="135" rows="3" id="URL" title="Name or title of associated file or URL. This title becomes a clickable link in the announcement." [(ngModel)]="announcementText.urlTitle">{{announcementText.urlTitle}}</textarea>
                    <!-- <%=announcementText.getAnnTxtUrlTitle()==null?"":announcementText.getAnnTxtUrlTitle()%> -->
                </td>
            </tr>
            <tr>
                <td><label for="path">Full Local Path to Associated File or URL:</label> (For example, "https://www.tbt4solutions.com/")<br />The path must point to the AnnouncementDocs folder on the Administrative Office Web server or an accessible location on the Internet.</td>
            </tr>
            <tr>
                <td>
                    <input type="text" name="annTxtUrl" size="50" maxlength="300"title="Full local path to the associated file on the WebERF server or to a URL on the Internet" [(ngModel)]="announcementText.url" />
                    <!-- <input type="text" name="annTxtUrl" size="50" maxlength="300"title="Full local path to the associated file on the WebERF server or to a URL on the Internet" 
                    value="<%=announcementText.getAnnTxtUrl()==null?"":announcementText.getAnnTxtUrl()%>" 
                    id="path" /> -->
                </td>
            </tr>
            <tr><td>&nbsp;</td></tr>
        </table>
        <!-- Date Section -->
        <table>
            <tr>
                <td>
                    <label for="title">Effective Date<span class="required">*</span>:</label>
                </td>
                <td>
                    <mat-form-field appearance="fill">
                        <mat-label>Choose Effective Date</mat-label>
                        <!-- <input matInput [matDatepicker]="picker3"[readonly]="true"  [(ngModel)]="tmpDemoQ.termDate"> -->
                        <input matInput [matDatepicker]="picker1" [readonly]="true" [(ngModel)]="announcementText.effDate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </td>
                <td>
                    <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" aria-label="Clear Date" (click)="announcementText.effDate = null" style="margin: -20px 2px 2px 2px;">Clear Date</button>
                </td>
            </tr>
            <tr>
                <td>
                    <label for="title">Expiration Date:</label>
                </td>
                <td>
                    <mat-form-field appearance="fill">
                        <mat-label>Choose Expiration Date</mat-label>
                        <!-- <input matInput [matDatepicker]="picker3"[readonly]="true"  [(ngModel)]="tmpDemoQ.termDate"> -->
                        <input matInput [matDatepicker]="picker2" [readonly]="true" [(ngModel)]="announcementText.expDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </td>
                <td>
                    <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" aria-label="Clear Date" (click)="announcementText.expDate = null" style="margin: -20px 2px 2px 2px;">Clear Date</button>
                </td>
            </tr>
        </table>
        <!-- Addition Area for EMPLOYER & AGREEMENT -->
        <!-- Agrement -->
        <ng-container *ngIf="announcementType == 2">
            <mat-table [dataSource]="agreementDataSource" class="mat-elevation-z8 existingAnnouncements">

                <!-- # Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index">
                        {{i+1}}
                    </mat-cell>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Agreement Title </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i =index">
                        <ng-container *ngIf="element.guid != null;else otherAgrement">
                            <select (change)="agreementChange($event)" [(ngModel)]="element.cbaGUID" disabled="true">
                                <!-- <option>--Select--</option> -->
                                <option *ngFor="let item of agreements" [ngValue]="item.agreement.masterAgreementGUID">
                                    {{item.agreement.agreementLongName}}
                                </option>
                            </select>
                        </ng-container>
                        <ng-template #otherAgrement>
                            <select (change)="agreementChange($event)" [(ngModel)]="element.cbaGUID">
                                <!-- <option>--Select--</option> -->
                                <option *ngFor="let item of agreements" [ngValue]="item.agreement.masterAgreementGUID">
                                    {{item.agreement.agreementLongName}}
                                </option>
                            </select>
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <!-- Remove Column -->
                <ng-container matColumnDef="remove">
                    <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index">
                        <button mat-icon-button color="warn" aria-label="Delete Data" (click)="checkDeleteItem(element,i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- ADD column -->
                <ng-container matColumnDef="add">
                    <td mat-footer-cell *matFooterCellDef colspan="3">
                        <button mat-flat-button color="primary" class="addRecord" (click)="addToAgreementDataSource()"><mat-icon>add</mat-icon> Agreement</button>
                    </td>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedAgreementColumns;"></mat-header-row><!--sticky: true //removed for visual fix with modal-->
                <mat-row *matRowDef="let row; columns: displayedAgreementColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="['add']" class="example-second-footer-row"></mat-footer-row>
            </mat-table>
        </ng-container>
        <!-- Employer -->
        <ng-container *ngIf="announcementType == 3">
            <mat-table [dataSource]="employerDataSource" class="mat-elevation-z8 existingAnnouncements">

                <!-- # Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index">
                        {{i+1}}
                    </mat-cell>
                </ng-container>
                <!-- # Column -->
                <ng-container matColumnDef="employer">
                    <mat-header-cell *matHeaderCellDef> Employer </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index">
                        <ng-container *ngIf="element.guid != null;else otherEmployer">
                            <select (change)="employerChange($event)" [(ngModel)]="element.erGUID" disabled="true">
                                <option *ngFor="let item of employers" [ngValue]="item.guid">
                                    {{item.organizationID}} - {{item.organizationName}}
                                </option>
                            </select>
                        </ng-container>
                        <ng-template #otherEmployer>
                            <select (change)="employerChange($event)" [(ngModel)]="element.erGUID">
                                <option *ngFor="let item of employers" [ngValue]="item.guid">
                                    {{item.organizationID}} - {{item.organizationName}}
                                </option>
                            </select>
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <!-- Remove Column -->
                <ng-container matColumnDef="remove">
                    <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-icon-button color="warn" aria-label="Delete Data" (click)="checkDeleteItem(element,i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- ADD column -->
                <ng-container matColumnDef="add">
                    <mat-footer-cell *matFooterCellDef colspan="3">
                        <button mat-flat-button color="primary" class="addRecord" (click)="addToEmployerDataSource()"><mat-icon>add</mat-icon> Employer</button>
                    </mat-footer-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedEmployerColumns;"></mat-header-row><!--sticky: true //removed for visual fix with modal-->
                <mat-row *matRowDef="let row; columns: displayedEmployerColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="['add']" class="example-second-footer-row"></mat-footer-row>
            </mat-table>
        </ng-container>
        
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="announcementObject.guid != null">
            <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="modal.close('Save click');fullDelete();">Delete</button>
        </ng-container>
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="accent" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="modal.dismiss('Cross click');open(previewModal)">Preview</button>
        <!-- Global -->
        <ng-container *ngIf="announcementType == 1">
            <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="saveGlobalData()">Save / Update</button>
        </ng-container>
        <!-- Agrement -->
        <ng-container *ngIf="announcementType == 2">
            <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="saveAgreementData()">Save / Update</button>
        </ng-container>
        <!-- Employer -->
        <ng-container *ngIf="announcementType == 3">
            <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="saveEmployerData()">Save / Update</button>
        </ng-container>
    </div>
</ng-template>



<!-- Preview Modal -->
<ng-template #previewModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Preview</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');open(createAnnouncement)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body announcements annContainer">
        <p class="announcehead">Announcements</p>

        <div class="annBlock annBackGround">
            <!-- <p class="announcetitle annBackGround">{{announcementText.header}}</p>
            <p class="announcetext annBackGround">{{announcementText.message}}</p>
            <ng-container *ngIf="announcementText.url != null && announcementText.urlTitle != null">
                <p class="announceurl annBackGround">
                    <a href="{{announcementText.url}}" target="_blank">{{announcementText.urlTitle}}</a>
                </p>
            </ng-container>
            <ng-container *ngIf="announcementText.footer != null">
                <p class="announcefooter annBackGround">{{announcementText.footer}}</p>
            </ng-container> -->
            <p class="announcetitle annBackGround" [innerHTML]="announcementText.header"></p>
            <p class="announcetext annBackGround" [innerHTML]="announcementText.message"></p>
            <ng-container *ngIf="announcementText.url != null && announcementText.urlTitle != null">
                <p class="announceurl annBackGround" >
                    <a href="{{announcementText.url}}" target="_blank" [innerHTML]="announcementText.urlTitle"></a>
                </p>
            </ng-container>
            <ng-container *ngIf="announcementText.footer != null">
                <p class="announcefooter annBackGround" [innerHTML]="announcementText.footer"></p>
            </ng-container>
        </div>

    </div>
</ng-template>
<!-- Delete Report Modal -->
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Announcement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');open(createAnnouncement)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="partialDeleteIndex == -1;else deleteAll">
            <!-- Global -->
            <ng-container *ngIf="announcementType == 1">
                Are you sure you want to delete this Global Announcement?
            </ng-container>
            <!-- Agreement -->
            <ng-container *ngIf="announcementType == 2">
                Are you sure you want to delete this Announcement for all attached Agreements?
            </ng-container>
            <!-- Employer -->
            <ng-container *ngIf="announcementType == 3">
                Are you sure you want to delete this Announcement for all attached Employers?
            </ng-container>
        </ng-container>
        <ng-template #deleteAll>
            Are you sure you want to delete this announcement for: 
            <!-- Agreement -->
            <ng-container *ngIf="announcementType == 2">
                <!-- {{agreementDataSource.data[partialDeleteIndex].agreement.agreementLongName}} -->
                {{showSingleDelteItem()[0].agreement.agreementLongName}}
            </ng-container>
            <!-- Employer -->
            <ng-container *ngIf="announcementType == 3">
                <!-- {{employerDataSource.data[partialDeleteIndex].organizationID}} - {{employerDataSource.data[partialDeleteIndex].organizationName}} -->
                {{showSingleDelteItem()[0].organizationID}} - {{showSingleDelteItem()[0].organizationName}}
            </ng-container>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="accent" (click)="modal.close('Save click');open(createAnnouncement)">Cancel</button>
        <ng-container *ngIf="partialDeleteIndex == -1;else deleteAll2">
            <!-- Global -->
            <ng-container *ngIf="announcementType == 1">
                <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="deleteGlobal()">Confirm</button>
            </ng-container>
            <!-- Agreement -->
            <ng-container *ngIf="announcementType == 2">
                <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="deleteAgreement(true)">Confirm</button>
            </ng-container>
            <!-- Employer -->
            <ng-container *ngIf="announcementType == 3">
                <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="deleteEmployer(true)">Confirm</button>
            </ng-container>
        </ng-container>
        <ng-template #deleteAll2>

            <!-- Agreement -->
            <ng-container *ngIf="announcementType == 2">
                <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="deleteAgreement(false)">Confirm</button>
            </ng-container>
            <!-- Employer -->
            <ng-container *ngIf="announcementType == 3">
                <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" (click)="deleteEmployer(false)">Confirm</button>
            </ng-container>
        </ng-template>
    </div>
</ng-template>