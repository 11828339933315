<mat-toolbar class="firstHeader">
  <mat-toolbar-row>
    <ng-container *ngIf="!gotClientHeader"></ng-container>
    <img *ngIf="gotClientHeader" [src]="allowImg()" alt="image" class="imgClient">
    <img *ngIf="gotClientHeader2" [src]="allowImg2()" alt="image" class="imgClient2">
    <span class="example-spacer"></span>
    <img src="/assets/images/PoweredByLogo.png" alt="image" class="imgTBT" (click)="newTab('http://www.tbt4solutions.com')">
  </mat-toolbar-row>
</mat-toolbar>
<!-- TODO: ADD Drop downs instead of home nav -->
<mat-toolbar class="secondHeader">
  <mat-toolbar-row>
    <!-- <span>Nav Bar</span> -->
    <div *ngIf="authService.isUserSignedin()">
      <span *ngIf="afterLogin()">
        <button mat-button [matMenuTriggerFor]="menu" class="menu">Menu
          <mat-icon>expand_more</mat-icon>
        </button>
      </span>
    </div>
    <span class="example-spacer"></span>
    <!-- Change Roles Section -->
    <ng-container *ngIf="authService.getManyRoles() && authService.getRoleList().length > 1">
      <button class="roles" mat-raised-button color="accent" (click)="open(chaneRoleModal)">Change Role</button>
    </ng-container>
    <span class="example-spacer"></span>
    <button class="small" mat-raised-button color="warn" *ngIf="authService.isUserSignedin()" (click)="logout()">Logout</button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button class="menuItems" mat-menu-item (click)="redirectTo('/home')">Home</button>
  
  <ng-container *ngIf="isWebMaster()">
    <button class="menuItems" mat-menu-item (click)="redirectTo('/webmaster/announcements')">Create/Edit Announcements</button>
    <ng-container *ngIf="systemParameters[11] != null && systemParameters[11] != undefined && isAffirmative(systemParameters[11].parameterValue1!)">
      <button class="menuItems" mat-menu-item (click)="redirectTo('/webmaster/termsOfUse')">Create/Edit Terms of Use</button>
    </ng-container>
  </ng-container>
  
  <!-- <ng-container *ngIf="isEmployerOrStaff() || isAdmin()"> -->
    <button class="menuItems" mat-menu-item (click)="redirectTo('/changePW')">Change Password</button>
    <!-- <li><a href="changePW">Change User ID / Password</a></li> -->
  <!-- </ng-container> -->

  <ng-container *ngIf="isLiaison()">
    <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','liaison')">Search Employers</button>
  </ng-container>

  <ng-container *ngIf="isWebMaster()">
    <!-- <button class="menuItems" mat-menu-item (click)="redirectTo('/editRegisteredEmployer')">Edit a Registered Employer</button> -->
    <button class="menuItems" mat-menu-item [matMenuTriggerFor]="webUtil">Webmaster Utilities</button>
    <button class="menuItems" mat-menu-item [matMenuTriggerFor]="webReports">Webmaster Administrative Reports</button>
  </ng-container>

  <ng-container *ngIf="!isAdmin() && !isWebMaster() && !isLiaison() && !isAuditor();else searchPage">
    <ng-container *ngIf="isPayRoll()">
      <button class="menuItems" mat-menu-item (click)="payRollGoToLink('/reportOptions')">Create a New Report</button>
      <button class="menuItems" mat-menu-item (click)="payRollGoToLink('/listReports')">View List of Pended and Finalized Reports</button>
    </ng-container>
    <ng-container *ngIf="!isPayRoll()">
      <button class="menuItems" mat-menu-item (click)="redirectTo('/reportOptions')">Create a New Report</button>
      <button class="menuItems" mat-menu-item (click)="redirectTo('/listReports')">View List of Pended and Finalized Reports</button>
    </ng-container>
  </ng-container>
  <ng-template #searchPage>
    <ng-container *ngIf="!isWebMaster() && !isLiaison()">
      <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','reportOptions')">Create a New Report</button>
      <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','listReports')">View List of Pended and Finalized Reports</button>
    </ng-container>
  </ng-template>


  <!-- <ng-container *ngIf="isEmployerOrStaff() && isAffirmative(systemParameters[2].parameter_VALUE)">
    <button class="menuItems" mat-menu-item [matMenuTriggerFor]="employerAR">Employer Administrative Reports</button>
  </ng-container> -->
  <ng-container *ngIf="systemParameters[3] != null && systemParameters[3] != undefined && isAffirmative(systemParameters[3].parameterValue1!)">
    <button class="menuItems" mat-menu-item (click)="redirectTo('/ErCbaView')">View Agreements and Rates</button>
    <!-- <li><a href="ErCbaView">View Agreements and Rates</a></li> -->
  </ng-container>

  <ng-container *ngIf="systemParameters[4] != null && systemParameters[4] != undefined && isAffirmative(systemParameters[4].parameterValue1!)">
    <ng-container *ngIf="isAdmin()">
      <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','listProject')">Add/Edit Employer Projects</button>
    </ng-container>
    <ng-container *ngIf="isEmployerOrStaff()">
      <button class="menuItems" mat-menu-item (click)="redirectTo('/listProject')">Add/Edit Projects</button>
      <!-- <li><a href="listProject">Add/Edit Projects</a></li>  -->
    </ng-container>
  </ng-container>

  <!-- <ng-container *ngIf="systemParameters[5] != null && systemParameters[5] != undefined && isAffirmative(systemParameters[5].parameterValue1!) && isEmployer()">
    <button class="menuItems" mat-menu-item (click)="redirectTo('/staffUserAccounts')">Setup / Edit Staff User Accounts</button>
  </ng-container> -->

  <ng-container *ngIf="systemParameters[7] != null && systemParameters[7] != undefined && isAffirmative(systemParameters[7].parameterValue1!) && isEmployerOrStaff()">
    <button class="menuItems" mat-menu-item (click)="redirectTo('/employerEFTaccountDetails')">Setup / Edit Employer EFT Account Details</button>
    <!-- <li>
      <a href="UpdateErBankAccount">Setup / Edit Employer EFT Account Details</a>&nbsp;&nbsp;
    </li> -->
  </ng-container>


  <ng-container *ngIf="!isAdmin() && !isWebMaster() && !isLiaison() && !isAuditor();else demoPage">
    <ng-container *ngIf="systemParameters[8] != null && systemParameters[8] != undefined && isAffirmative(systemParameters[8].parameterValue1!)">
      <ng-container *ngIf="isPayRoll()">
        <button class="menuItems" mat-menu-item (click)="payRollGoToLink('/employeeDemographics')">Employee Demographics</button>
      </ng-container>
      <ng-container *ngIf="!isPayRoll()">
        <button class="menuItems" mat-menu-item (click)="redirectTo('/employeeDemographics')">Employee Demographics</button>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #demoPage>
    <ng-container *ngIf="!isWebMaster() && !isLiaison() && !isAuditor()">
      <ng-container *ngIf="systemParameters[8] != null && systemParameters[8] != undefined && isAffirmative(systemParameters[8].parameterValue1!)">
        <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','employeeDemographics')">Employee Demographics</button>
      </ng-container>
    </ng-container>
  </ng-template>
<!-- CENSUS IMPORT ADMIN/EMPLOYER ACCESS -->
  <ng-container *ngIf="!isAdmin() && !isWebMaster() && !isLiaison() && !isAuditor();else censusPage">
    <ng-container *ngIf="isPayRoll() && systemParameters[10] != null && systemParameters[10] != undefined && isAffirmative(systemParameters[10].parameterValue1!)">
      <button class="menuItems" mat-menu-item (click)="payRollGoToLink('/censusImport')">Census Import</button>
      <!-- <button class="menuItems" mat-menu-item (click)="payRollGoToLink('/specialMapping')">Special Import Mappings</button> -->
    </ng-container>
    <ng-container *ngIf="!isPayRoll()">
      <ng-container *ngIf="systemParameters[10] != null && systemParameters[10] != undefined && isAffirmative(systemParameters[10].parameterValue1!)">
        <button class="menuItems" mat-menu-item (click)="redirectTo('/censusImport')">Census Import</button>
      </ng-container>
      <button class="menuItems" mat-menu-item (click)="redirectTo('/specialMapping')">Special Import Mappings</button>
    </ng-container>
  </ng-container>
  <ng-template #censusPage>
    <ng-container *ngIf="!isWebMaster() && !isLiaison() && !isAuditor()">
      <ng-container *ngIf="systemParameters[10] != null && systemParameters[10] != undefined && isAffirmative(systemParameters[10].parameterValue1!)">
        <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','censusImport')">Census Import</button>
      </ng-container>
      <button class="menuItems" mat-menu-item (click)="adminRedirectTo('/searchEmployers','specialMapping')">Special Import Mappings</button>
    </ng-container>
  </ng-template>


  <ng-container *ngIf="systemParameters[1] != null && systemParameters[1] != undefined && systemParameters[1].parameterValue1 != '' && systemParameters[1].parameterValue1 != null && isEmployerOrStaff()">
    <button class="menuItems" mat-menu-item [innerHTML]="systemParameters[1].parameterValue1"></button>
  </ng-container>

  <ng-container *ngIf="isEmployer()">
    <ng-container *ngIf="systemParameters[9] != null && systemParameters[9] != undefined && isAffirmative(systemParameters[9].parameterValue1!)">
      <button class="menuItems" mat-menu-item (click)="redirectTo('/manage-staff')">Manage Staff</button>
    </ng-container>
  </ng-container>

  <button class="menuItems" mat-menu-item (click)="redirectTo('/contact-us')">Contact Us</button>

</mat-menu>

<mat-menu #webUtil="matMenu">
  <button class="menuItems" mat-menu-item (click)="redirectTo('/webmaster/util/processes')">Processes</button>
  <button class="menuItems" mat-menu-item (click)="redirectTo('/webmaster/util/database')">Database</button>
</mat-menu>
<mat-menu #webReports="matMenu">
  <button class="menuItems" mat-menu-item (click)="redirectTo('/employerRoster')">Employer Roster</button>
  <button class="menuItems" mat-menu-item (click)="redirectTo('/agreementsList')">Agreements List</button>
  <button class="menuItems" mat-menu-item (click)="redirectTo('/employerReportingStatistics')">Employer Reporting Statistics</button>
</mat-menu>
<mat-menu #employerAR="matMenu">
  <button class="menuItems" mat-menu-item (click)="redirectToEmployerReport('/administrativeReports')">Employer Contributions Ledger</button>
  <button class="menuItems" mat-menu-item (click)="redirectToEmployeeReport('/administrativeReports')">Employee Contributions Ledger</button>
</mat-menu>








<!-- Change Role Modal -->
<ng-template #chaneRoleModal let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Change Assigned Role</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <label style="font-weight: 400;color:red">Note: Save any work before proceeding, changing roles will send you to the home page.</label>
      <br><br>
      <label>Current Role is: </label><label style="font-weight: bold;"> {{role.substring(6)}}</label><br>
      <label>Change Role to: </label><label style="color: green; font-weight: bold;">{{roleID}}</label><br>
      <select [(ngModel)]="roleID">
        <option *ngFor="let item of authService.getRoleList();let i = index">{{item}}</option>
      </select>
  </div>
  <div class="modal-footer">
      <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
      <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" [disabled]="roleID == '' || roleID == null || roleID == undefined" (click)="authService.changeRole(roleID);modal.close('Save click');reloadPage()">Change Role</button>
  </div>
</ng-template>